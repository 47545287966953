import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import GenericButton from "../../components/ui/GenericButton";
import PageTitle from "../../components/ui/PageTitle";
import {useAuth} from "../../hooks/useAuth";
import {Link, useNavigate} from "react-router-dom";
import CustomDataTable, {Order} from "../../components/ui/CustomDataTable";
import BoxContent from "../../components/ui/BoxContent";
import Loading from "../../components/ui/Loading";
import moment from "moment";
import {SubmitHandler} from "react-hook-form";
import SearchForm, {IFormInputs} from "../../components/form/SearchForm";
import {APIGetCustomItems} from "../../api/pages/customItems";
import Icon from "../../components/ui/Icon";
import {APIDeleteCustomItem} from "../../api/pages/customItem";
// import { useAuth } from "hooks/useAuth";

const CustomTestItems = () => {
    const { update, user } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [items, setItems] = useState<any[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [perPage, setPerPage] = useState<number>(15);
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<string>("id");
    const [query, setQuery] = useState<string>("");
    const [search, setSearch] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        APIGetCustomItems({ page: `?page=${page + 1}`, sort: orderBy, order: order, search: query })
            .then((response) => {
                if (response.data.success) {
                    // console.log("getTeachers OK", response);
                    setItems(response.data.data.data.data);
                    setTotalRows(response.data.data.data.total);
                    setPerPage(response.data.data.data.per_page);
                    setLoading(false);
                } else {
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                // console.log("getTeachers ERR", err);
                update("error", { show: true, code: "A001" });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, order, orderBy, query, search]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        setPage(0);
    };

    const handleSearch: SubmitHandler<IFormInputs> = (data) => {
        setQuery(data.keywords);
        setPage(0);
        setSearch(true);
    };

    const handleClearSearch = () => {
        setQuery("");
        setSearch(false);
        setPage(0);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setItems([]);
        setPage(newPage);
    };
    const editItemHandler = (id: number) => {
        navigate(`/itemi-proprii/${id}/editare`);
    };
    const addItemHandler = () => {
        navigate(`/itemi-proprii/adauga`);
    };

    const deleteItem = (id: string, callback: Function) => {
        update("loading", { show: true, modal: true });
        APIDeleteCustomItem({ id })
            .then((response) => {
                if (response.data.success) {
                    // console.log("deleteTeacher OK", response);
                    update("loading", { show: false });
                    setItems(items.filter((item) => item.id.toString() !== id.toString()));
                    setTotalRows((prevState) => prevState - 1);
                    // navigate("/profesori");
                } else {
                    update("loading", { show: false });
                    update("error", { show: true, code: "A002" });
                }
                callback();
            })
            .catch((err) => {
                // console.log("deleteTeacher ERR", err);
                update("loading", { show: false });
                update("error", { show: true, code: "A001" });
            });
    };

    const confirmDelete = (item: {
        id: number;
        grade: string;
        category: string;
        subcategory: string;
        [key: string]: any;
    }) => {
        update("confirm", {
            show: true,
            closable: true,
            title: "Ești sigur că vrei să ștergi acest item?",
            message: (
                <>
                    Acțiunea va șterge definitiv itemul{" "}
                    <strong>{item.title}</strong>!
                </>
            ),
            returnFunction: (callback: Function) => {
                deleteItem(item.id.toString(), callback);
            },
            buttonYes: "Șterge",
            buttonNo: "Închide",
        });
    };

  return (
    <Box>
        <PageTitle
            actions={
                user.position === "teacher" ? (
                        <GenericButton
                            type="add"
                            onClick={() => {
                                addItemHandler();
                            }}
                        />
                    ) : (
                        ""
                    )
            }
        >
            Itemi proprii
        </PageTitle>
        <BoxContent>
            <Box display="flex" justifyContent="end" width="100%" sx={{ marginBottom: { xs: 1, sm: 3 },}}>
                {/*<CustomItemsSearchForm*/}
                {/*    onSubmit={handleSearch}*/}
                {/*    onChange={(e) => setQuery(e.target.value)}*/}
                {/*    onClick={handleClearSearch}*/}
                {/*    query={query}*/}
                {/*/>*/}
                <SearchForm
                    onSubmit={handleSearch}
                    onChange={(e) => setQuery(e.target.value)}
                    onClick={handleClearSearch}
                    query={query}
                />
            </Box>
            {loading ? (
                <Loading show={true} />
            ): items.length === 0 ? (
                <Box>Niciun item introdus în platformă.</Box>
            ) : (
                <CustomDataTable
                    columns={[
                        {
                            id: "id",
                            label: "ID",
                            numeric: false,
                            noWrap: true,
                            disablePadding: false,
                            sortable: true,
                        },
                        {
                            id: "title",
                            label: "Titlu",
                            numeric: false,
                            noWrap: true,
                            disablePadding: false,
                            sortable: true,
                        },
                        {
                            id: "grade",
                            label: "Clasă",
                            numeric: false,
                            noWrap: true,
                            disablePadding: false,
                            sortable: true,
                        },
                        {
                            id: "subject",
                            label: "Materie",
                            numeric: false,
                            noWrap: true,
                            disablePadding: false,
                            sortable: true,
                        },
                        {
                            id: "subcategory",
                            label: "Subcategorie",
                            numeric: false,
                            noWrap: true,
                            tooltip: true,
                            disablePadding: false,
                            sortable: true,
                        },
                        {
                            id: "created_at",
                            label: "Dată",
                            numeric: false,
                            noWrap: true,
                            disablePadding: false,
                            sortable: true,
                        },
                        {
                            id: "is_active",
                            label: "Status",
                            numeric: false,
                            noWrap: true,
                            disablePadding: false,
                        },
                        {
                            id: "actions",
                            label: "Acțiuni",
                            numeric: true,
                            noWrap: false,
                            disablePadding: true,
                        },
                    ]}
                    rows={items.map((item) => {
                        return {
                            id: item.id,
                            title: (
                                <Link to={`/itemi-proprii/${item.id}`}>
                                    {item.title}
                                </Link>
                            ),
                            grade: item.grade,
                            subject: item.subject,
                            subcategory: item.subcategory_name,
                            created_at: moment(item.created_at).locale("ro").format("HH:mm, DD.MM.YYYY"),
                            is_active: (
                                item.is_active ? (
                                    <Box sx={{ width: "100%", paddingLeft: "10px" }}>
                                        <Icon
                                            icon="fas circle-check"
                                            fixedWidth={true}
                                            sx={{ fontSize: "1.5rem", color: "success.main" }}
                                        />
                                    </Box>
                                ) : (
                                    <Box sx={{ width: "100%", paddingLeft: "10px" }}>
                                        <Icon
                                            icon="fas circle-xmark"
                                            fixedWidth={true}
                                            sx={{ fontSize: "1.5rem", color: "error.main" }}
                                        />
                                    </Box>
                                )
                            ),
                        actions: (
                                user.position === "teacher" ? (
                                    <Box display="flex" justifyContent="end">
                                        <Box mr={1}>
                                        <GenericButton
                                            type="edit-small"
                                            onClick={() => {
                                                editItemHandler(item.id);
                                            }}
                                        />
                                        </Box>
                                        <Box>
                                            <GenericButton type="delete" onClick={() => confirmDelete(item)} />
                                        </Box>
                                    </Box>
                                ) : (
                                    <GenericButton
                                        type="items"
                                        onClick={() => {
                                            navigate(`/itemi-proprii/${item.id}`);
                                        }}
                                    />
                                )
                        ),
                        };
                    })}
                    rowsPerPage={perPage}
                    page={page}
                    totalRows={totalRows}
                    onPageChange={handleChangePage}
                    handleRequestSort={handleRequestSort}
                    orderBy={orderBy}
                    order={order}
                />
            )}
        </BoxContent>
    </Box>
  );
};

export default CustomTestItems;
