import React, {useEffect, useState} from "react";
import {APIGetCustomItems} from "../../../api/pages/customItems";
import CustomDataTable, {Order} from "../../ui/CustomDataTable";
import {useAuth} from "../../../hooks/useAuth";
import Loading from "../../ui/Loading";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";
import Icon from "../../ui/Icon";
import GenericButton from "../../ui/GenericButton";
import BoxContent from "../../ui/BoxContent";
import CustomButton from "../../ui/CustomButton";
import Typography from "@mui/material/Typography";
import PageTitle from "../../ui/PageTitle";
import {SubmitHandler} from "react-hook-form";
import {IFormInputs} from "../../form/SearchForm";
import CustomItemsSearchForm from "../../form/CustomItemsSearchForm";
import TestItem from "../../ui/TestItem";

const CustomItemsList = ({
         loading,
         setLoading,
         createTestData,
         setCustomItemsHandler,
         removeCustomItemHandler,
         setSelectedCategoryHandler,
         selectedItems,
         resetSelectedCategory,
} : any) => {
    const { update } = useAuth();
    const navigate = useNavigate();
    const [customItems, setCustomItems] = useState<any[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [perPage, setPerPage] = useState<number>(15);
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<string>("id");
    const [query, setQuery] = useState<string>("");
    const [search, setSearch] = useState<boolean>(false);

    const getCustomItemsHandler = () => {
        APIGetCustomItems({ page: `?page=${page + 1}`, sort: orderBy, order: order, search: query, subject: createTestData.subject.value, grade: createTestData.grade.value, subcategory: createTestData.category.value, active_items: true})
            .then((response) => {
                if (response.data.success) {
                    // console.log("getTeachers OK", response);
                    setCustomItems(response.data.data.data.data);
                    setTotalRows(response.data.data.data.total);
                    setPerPage(response.data.data.data.per_page);
                    setLoading({ loading: false, name: "step" });
                } else {
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                // console.log("getTeachers ERR", err);
                update("error", { show: true, code: "A001" });
            });
    }

    useEffect(() => {
      getCustomItemsHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, order, orderBy, search]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setCustomItems([]);
        setPage(newPage);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        setPage(0);
    };

    const addItemHandler = () =>{
        navigate(`/itemi-proprii/adauga`);
    }

    const previewItemHandler = (item: any) => {
        if(selectedItems.filter((selectedItem: any) => selectedItem.id.toString() === item.id.toString()).length !== 0) {
            update("confirm", {
                show: true,
                closable: true,
                title: item.title,
                message: (
                    <TestItem item={item} color="light"/>
                ),
                returnFunction: () => {removeCustomItemHandler(item)},
                buttonYes: "Sterge",
                buttonNo: "Închide",
                size: "big"
            });
        } else {
            update("confirm", {
                show: true,
                closable: true,
                title: item.title,
                message: (
                    <TestItem item={item} color="light"/>
                ),
                returnFunction: () => {setCustomItemsHandler(item)},
                buttonYes: "Adauga",
                buttonNo: "Închide",
                size: "big"
            });
        }
    }

    const handleSearch: SubmitHandler<IFormInputs> = () => {
        getCustomItemsHandler();
        setPage(0);
        setSearch(!search);
    };

    const handleClearSearchQuery = () => {
        setQuery("");
        setSearch(!search);
        setPage(0);
    };

    const handleClearSearchSubcategory = () => {
        resetSelectedCategory();
        setSearch(!search);
        setPage(0);
    };

    const onChange = (e: any) => {
        if(e.target.value !== undefined) {
            setQuery(e.target.value)
        }
    }

    return (
        <Box width="100%">
            <PageTitle>Selectare itemi</PageTitle>
            <BoxContent>
                <Box display="flex" justifyContent="end" width="100%" sx={{ marginBottom: { xs: 1, sm: 3 },}}>
                    <CustomItemsSearchForm
                        onSubmit={handleSearch}
                        onChange={onChange}
                        onClickQuerry={handleClearSearchQuery}
                        onClickSubcategory={handleClearSearchSubcategory}
                        query={query}
                        createTestData={createTestData}
                        setSelectedCategoryHandler={setSelectedCategoryHandler}
                    />
                </Box>
                {loading.loading && loading.name === "step" ? (
                    <>
                        <Loading show={true}/>
                    </>
                ) : customItems.length === 0 ? (
                        <>
                            <Typography variant="body1">Niciun item introdus în platformă.</Typography>
                            <CustomButton onClick={addItemHandler} buttonType="textIcon" bgcolor="dark" icon={<Icon icon="plus" fixedWidth={true} sx={{ fontSize: "1.25rem" }} />}>
                                Adaugă item
                            </CustomButton>
                        </>
                ) : (
                    <CustomDataTable
                        columns={[
                            {
                                id: "id",
                                label: "ID",
                                numeric: false,
                                noWrap: true,
                                disablePadding: false,
                                sortable: true,
                            },
                            {
                                id: "title",
                                label: "Titlu",
                                numeric: false,
                                noWrap: true,
                                disablePadding: false,
                                sortable: true,
                            },
                            {
                                id: "grade",
                                label: "Clasă",
                                numeric: false,
                                noWrap: true,
                                disablePadding: false,
                                sortable: true,
                            },
                            {
                                id: "subject",
                                label: "Materie",
                                numeric: false,
                                noWrap: true,
                                disablePadding: false,
                                sortable: true,
                            },
                            {
                                id: "subcategory",
                                label: "Subcategorie",
                                numeric: false,
                                noWrap: true,
                                tooltip: true,
                                disablePadding: false,
                                sortable: true,
                            },
                            {
                                id: "created_at",
                                label: "Dată",
                                numeric: false,
                                noWrap: true,
                                disablePadding: false,
                                sortable: true,
                            },
                            {
                                id: "status",
                                label: "Adăugat",
                                numeric: false,
                                noWrap: false,
                                disablePadding: true,
                            },
                            {
                                id: "actions",
                                label: "Acțiuni",
                                numeric: true,
                                noWrap: false,
                                disablePadding: true,
                            },
                        ]}
                        rows={customItems.map((item) => {
                            return {
                                id: item.id,
                                title: item.title,
                                grade: item.grade,
                                subject: item.subject,
                                subcategory: item.subcategory_name,
                                created_at: moment(item.created_at).locale("ro").format("HH:mm, DD.MM.YYYY"),
                                status: (
                                    selectedItems.filter((selectedItem: any) => selectedItem.id.toString() === item.id.toString()).length !== 0 ? (
                                        <Icon
                                            icon="fas circle-check"
                                            fixedWidth={true}
                                            sx={{fontSize: "1.5rem", color: "success.main"}}
                                        />
                                        ) : (
                                        <Icon
                                            icon="fas circle-check"
                                            fixedWidth={true}
                                            sx={{fontSize: "1.5rem", color: "text.disabled"}}
                                        />
                                        )
                                ),
                                actions: (
                                    <Box display="flex" justifyContent="end">
                                        <Box mr={1}>
                                            <GenericButton
                                                type="items"
                                                onClick={() => {
                                                    previewItemHandler(item);
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            {selectedItems.filter((selectedItem: any) => selectedItem.id.toString() === item.id.toString()).length !== 0 ? (
                                                    <GenericButton type="delete"  onClick={() => {
                                                        removeCustomItemHandler(item);
                                                    }}/>
                                                ) : (
                                                    <GenericButton
                                                        type="add-small"
                                                        onClick={() => {
                                                            setCustomItemsHandler(item);
                                                        }}
                                                    />
                                                )
                                            }
                                        </Box>
                                    </Box>
                                ),
                            };
                        })}
                        rowsPerPage={perPage}
                        page={page}
                        totalRows={totalRows}
                        onPageChange={handleChangePage}
                        handleRequestSort={handleRequestSort}
                        orderBy={orderBy}
                        order={order}
                    />
                )}
            </BoxContent>
        </Box>
    )
}

export default CustomItemsList;