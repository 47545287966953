import React from "react";
import Box from "@mui/material/Box";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import PageTitle from "components/ui/PageTitle";
import BoxContent from "components/ui/BoxContent";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useRouteMatch } from "utils/functions";
import GenericButton from "../../components/ui/GenericButton";
import StudentsActive from "../../components/students/StudentsActive";
import StudentsArchived from "../../components/students/StudentsArchived";

const Students = () => {
    const { user, tenant } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const routeMatch = useRouteMatch(["/elevi", "/elevi/arhiva"]);
    const currentTab = routeMatch?.pattern?.path;

    return (
        <Box>
            <PageTitle
                actions={
                    <GenericButton
                        type="add"
                        onClick={() => {
                            navigate(`/elevi/adauga`);
                        }}
                    />
                }
            >
                Elevi
            </PageTitle>
            <BoxContent>
                <>
                {(user.position === "principal" || (tenant.type === "teacher" && user.position === "teacher")) && (
                    <Box sx={{ width: "100%" }}>
                        <Box mb={2} sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={currentTab}
                                sx={{
                                    "& .MuiTabs-flexContainer": {
                                        flexWrap: "wrap",
                                    },
                                }}
                            >
                                <Tab label="Elevi activi" value="/elevi" to="/elevi" component={Link} />
                                <Tab
                                    label="Elevi arhivați"
                                    value="/elevi/arhiva"
                                    to="/elevi/arhiva"
                                    component={Link}
                                />
                            </Tabs>
                        </Box>
                        {location.pathname === "/elevi" && <StudentsActive />}
                        {location.pathname === "/elevi/arhiva" && <StudentsArchived />}
                    </Box>
                )}
                {(tenant.type === "school" && user.position === "teacher") && (
                    <Box sx={{ width: "100%" }}>
                        {location.pathname === "/elevi" && <StudentsActive />}
                    </Box>
                )}
                </>
            </BoxContent>
        </Box>
    );
};

export default Students;
