import {useAuth} from "../../hooks/useAuth";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useRouteMatch} from "../../utils/functions";
import Box from "@mui/material/Box";
import PageTitle from "../../components/ui/PageTitle";
import CustomButton from "../../components/ui/CustomButton";
import Icon from "../../components/ui/Icon";
import BoxContent from "../../components/ui/BoxContent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TestsActive from "../../components/tests/TestsActive";
import TestsHistory from "../../components/tests/TestsHistory";
import React from "react";

const TestsCustom = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const routeMatch = useRouteMatch(["/teste-proprii", "/teste-proprii/teste-finalizate"]);
    const currentTab = routeMatch?.pattern?.path;

    return (
        <Box>
            <PageTitle
                actions={
                    <>
                        {user.position === "teacher" ? (
                            <CustomButton
                                buttonType="textIcon"
                                onClick={() => {
                                    navigate("/teste-proprii/adauga-test");
                                }}
                                icon={<Icon icon="fas plus" fixedWidth={true} />}
                            >Generează test</CustomButton>
                        ) : (
                            ""
                        )}
                    </>
                }
            >
                Teste proprii
            </PageTitle>
            <BoxContent>
                <>
                    <Box sx={{ width: "100%" }}>
                        <Box mb={2} sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={currentTab}
                                sx={{
                                    "& .MuiTabs-flexContainer": {
                                        flexWrap: "wrap",
                                    },
                                }}
                            >
                                <Tab
                                    label="Teste active"
                                    value="/teste-proprii"
                                    to="/teste-proprii"
                                    component={Link}
                                    sx={{color: "secondary.light", "& :hover, & .active": "primary.main"}}
                                />
                                <Tab
                                    label="Teste finalizate"
                                    value="/teste-proprii/teste-finalizate"
                                    to="/teste-proprii/teste-finalizate"
                                    component={Link}
                                    sx={{color: "secondary.light", "& :hover, & .active": "primary.main"}}
                                />
                            </Tabs>
                        </Box>
                        {location.pathname === "/teste-proprii" && <TestsActive type="customTest" />}
                        {location.pathname === "/teste-proprii/teste-finalizate" && <TestsHistory type="customTest" />}
                    </Box>
                </>
            </BoxContent>
        </Box>
    );
}

export default TestsCustom;