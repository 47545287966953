import {IMedia, useAuth} from "../../../hooks/useAuth";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Icon from "../Icon";
import CustomTextEditorImages from "./CustomTextEditorImages";

const MediaModal = ({ media, children }: IMedia) => {
    const { update } = useAuth();

    const closeHandler = () => {
        update("media", { show: false });
        media.cancelFunction?.();
    };

    const okHandler = () => {
        if (typeof media.returnFunction !== "undefined") {
            media.returnFunction(() => {
                update("media", {}, true);
            });
            update("media", {}, true);
        } else {
            update("media", {}, true);
        }
    };

    return media.show ? (
        <>
            <Dialog
                open={media.show}
                // onClose={closeHandler}
                onClose={() => {
                    return media.closable ? closeHandler() : null;
                }}
                fullWidth={true}
                maxWidth="lg"
                PaperComponent={Paper}
                PaperProps={{
                    sx: (theme) => ({
                        backgroundColor: "info.light",
                        backgroundImage: "none",
                        padding: theme.spacing(0, 1),
                    }),
                }}
                sx={{ backgroundColor: "rgba(0, 40, 155, 0.5)" }}
            >
                <DialogTitle display="flex" alignItems="center" justifyContent="space-between" gap={1} sx={{ m: 0, p: 2 }}>
                    <Box flexGrow={1} py={1}>
                        <Typography variant="h3" color="info.contrastText">
                            {media.title}
                        </Typography>
                    </Box>
                    {media.closable && (
                        <IconButton onClick={closeHandler} aria-label="close">
                            <Icon icon="fal xmark" fixedWidth={true} sx={{ color: "primary.main" }} />
                        </IconButton>
                    )}
                </DialogTitle>
                <CustomTextEditorImages
                    closeHandler={closeHandler}
                    okHandler={okHandler}
                    selectImageHandler={media.onClickFunction}
                    selectedFile={media.data}
                />

            </Dialog>
            {children}
        </>
    ) : null;

}

export default MediaModal;