import React, {useEffect} from "react";
import { useAuth } from "hooks/useAuth";
// import ThemeToggle from "components/ThemeToggle";
import {useLocation, useNavigate} from "react-router-dom";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import Box from "@mui/material/Box";
import FormField from "../../components/form/FormField";
import Button from "@mui/material/Button";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {APIPostLoginSpecial} from "../../api/pages/login";

export interface IFormLogin {
    user_code: string;
    domain: string;
}

const schema = yup
    .object({
    })
    .required();

type LocationProps = {
    state: {
        message: string;
    };
};

const LoginTestsPage = () => {
    const { login, update } = useAuth();
    const navigate = useNavigate();
    let location = useLocation() as unknown as LocationProps;
    const message = location.state?.message || "";

    const methods = useForm<IFormLogin>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (message) {
            update("error", {
                show: true,
                message: message,
                title: "",
                icon: "fat face-smile",
                closable: true,
                button: "ÎNCHIDE"
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit: SubmitHandler<IFormLogin> = (data) => {
        update("loading", { show: true, modal: true });

        APIPostLoginSpecial({
            domain: data.domain,
            user_code: data.user_code,})
            .then((response) => {
                if (response.data.success && response.data.data?.token !== null) {
                    // console.log("postLOGIN OK", response);
                    login({
                        firstname: response.data.data.auth.first_name || "",
                        lastname: response.data.data.auth.last_name || "",
                        email: response.data.data.auth.email || "",
                        position: response.data.data.auth.position || "",
                        profilePhoto: response.data.data.auth.profile_photo || "",
                        token: `Bearer ${response.data.data.auth.token}`,
                        menu: response.data.data.auth.menu || [],
                        email_verified: response.data.data.auth.email_verified,
                        id: response.data.data.auth.id,
                        notifications: response.data.data.auth.notifications_unread,
                        date: new Date(),
                    });
                    let tenantNew = response.data.data.domain;
                    delete tenantNew.label;
                    delete tenantNew.value;
                    update("tenant", tenantNew);
                    if (response.data.data?.email_verified === null) {
                        navigate("/verifica-email");
                    }
                    update("loading", { show: false });
                    methods.clearErrors();
                } else {
                    update("loading", { show: false });
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                // console.log("postLOGIN ERR", err);
                if (err.response.status === 400) {
                    let errors = err.response.data.data;
                    if (errors.domain) {
                        methods.setError("domain", { type: "server", message: errors.domain[0] });
                    }
                    if (errors.user_code) {
                        methods.setError("user_code", { type: "server", message: errors.user_code[0] });
                    }
                    update("loading", { show: false });
                } else {
                    update("error", { show: true, code: "A001" });
                }
            });
    };

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                    <Box py={1}>
                        <FormField
                            name="domain"
                            type="text"
                            defaultValue=""
                            label="Cod școală"
                            autoFocus={false}
                            errorMessage={methods.formState.errors.domain?.message}
                        />
                    </Box>
                    <Box py={1}>
                        <FormField
                            name="user_code"
                            type="text"
                            defaultValue=""
                            label="Cod utilizator"
                            autoFocus={false}
                            errorMessage={methods.formState.errors.user_code?.message}
                        />
                    </Box>
                    <Box
                        sx={{
                            mt: 1,
                            mb: 3,
                        }}
                    >
                        <Button type="submit" variant="contained">
                            Autentificare
                        </Button>
                    </Box>
                </form>
            </FormProvider>
        </>
    )
};

export default LoginTestsPage;
