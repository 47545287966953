import {MathJax} from "better-react-mathjax";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ScrollBar from "./ScrollBar";
import Icon from "./Icon";
import React from "react";
import FormLabelBox from "./FormLabelBox";
import FormFieldBox from "./FormFieldBox";
import Grid from "@mui/material/Grid";

const TestItem = ({item, color = "dark", itemPreview = false} :any) => {
    return (
        <Box width="100%" display="flex" alignItems="center">
            <MathJax inline dynamic style={{ width: "100%" }}>
                {item.q_data !== "" && (
                    <Box mb={2}>
                        <Typography variant="h4">ENUNȚ</Typography>
                        <ScrollBar forceVisible="x" autoHide={false} style={{maxHeight: "100%", maxWidth: "100%", overflowY: "hidden"}} >
                            <Box component="div" width="100%" mb={1.5} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}>
                                <Box dangerouslySetInnerHTML={{ __html: item.q_data }}></Box>
                            </Box>
                        </ScrollBar>
                    </Box>
                )}

                {item.q_obs !== "" && (
                    <Typography variant="h4">CERINȚĂ</Typography>
                )}
                <ScrollBar forceVisible="x" autoHide={false} style={{overflowY: "hidden"}}>
                    {item.q_obs !== "" && (
                        <Typography component="div" variant="body1" width="100%" mb={2} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}>
                            <Box dangerouslySetInnerHTML={{ __html: item.q_obs }}></Box>
                        </Typography>
                    )}
                    <Box width="fit-content" minWidth="100%">
                        <Box
                            mb={2}
                            sx={{
                                padding: 2,
                                border: color === "light" ? "1px solid #ddd" : "1px solid #fff",
                            }}
                            width="100%"
                        >
                            <Typography component="div" dangerouslySetInnerHTML={{ __html: item.q_1 }} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}/>
                        </Box>
                        <Box
                            mb={2}
                            sx={{
                                padding: 2,
                                border: color === "light" ? "1px solid #ddd" : "1px solid #fff",
                            }}
                            width="100%"
                        >
                            <Typography component="div" dangerouslySetInnerHTML={{ __html: item.q_2 }} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}/>
                        </Box>
                        <Box
                            mb={2}
                            sx={{
                                padding: 2,
                                border: color === "light" ? "1px solid #ddd" : "1px solid #fff",
                            }}
                            width="100%"
                        >
                            <Typography component="div" dangerouslySetInnerHTML={{ __html: item.q_3 }} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}/>
                        </Box>
                        <Box
                            mb={2}
                            sx={{
                                padding: 2,
                                border: color === "light" ? "1px solid #ddd" : "1px solid #fff",
                            }}
                            width="100%"
                        >
                            <Typography component="div" dangerouslySetInnerHTML={{ __html: item.q_4 }} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}/>
                        </Box>
                        {itemPreview && (
                            <Grid container spacing={1.5} alignItems="center">
                                <FormLabelBox title="Materie:" />
                                <FormFieldBox>{item.subject.name}</FormFieldBox>
                                <FormLabelBox title="Clasa:" />
                                <FormFieldBox>{item.grade.name}</FormFieldBox>
                                <FormLabelBox title="Subcategorie:" />
                                <FormFieldBox>{item.subcategory.name}</FormFieldBox>
                                <FormLabelBox title="Raspuns corect:" />
                                <FormFieldBox>{item.right_answer}</FormFieldBox>
                                <FormLabelBox title="Item activ:" />
                                <FormFieldBox>
                                    {
                                        item.is_active ? (
                                            <Icon
                                                icon="fas circle-check"
                                                fixedWidth={true}
                                                sx={{fontSize: "1.5rem", color: "success.main"}}
                                            />
                                        ) : (
                                            <Icon
                                                icon="fas circle-xmark"
                                                fixedWidth={true}
                                                sx={{fontSize: "1.5rem", color: "error.main"}}
                                            />
                                        )
                                    }
                                </FormFieldBox>
                            </Grid>
                        )}
                    </Box>
                </ScrollBar>
            </MathJax>
        </Box>
    )
}

export default TestItem;