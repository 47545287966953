import React, {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import BoxContent from "components/ui/BoxContent";
import PageTitle from "components/ui/PageTitle";
import Loading from "components/ui/Loading";
import { useAuth } from "hooks/useAuth";
import FormField from "components/form/FormField";
import ErrorsList from "components/ui/ErrorsList";
import { useLocation } from "react-router-dom";
import FormLabelBox from "components/ui/FormLabelBox";
import FormFieldBox from "components/ui/FormFieldBox";
import GenericButton from "components/ui/GenericButton";
import CustomButton from "../../components/ui/CustomButton";
import {SelectChangeEvent} from "@mui/material/Select";
import {APIPostCategories, APIPostGrades} from "../../api/pages/createTest";
import CheckBoxField from "../../components/form/CheckBoxField";
import {APIPostCustomItem, APIUpdateCustomItem, APIGetCustomItem} from "../../api/pages/customItem";
import TestItem from "../../components/ui/TestItem";
import CustomTextEditor from "../../components/ui/textEditor/CustomTextEditor";
import Quill from "quill";
export interface IFormCustomItemsEdit {
    subject: string;
    grade: string;
    subcategory: string;
    title: string,
    right_answer: string;
    is_active: boolean;
    q_data: string;
    q_obs: string;
    q_1: string;
    q_2: string;
    q_3: string;
    q_4: string;
    duration: string;
}

const schema = yup
    .object({
    })
    .required();

const Delta = Quill.import('delta');

const CustomTestItem = () => {

    const { id = "", action } = useParams();
    const { update, user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const methods = useForm<IFormCustomItemsEdit>({
        resolver: yupResolver(schema),
    });
    const [loading, setLoading] = useState<boolean>(true);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [item, setItem] = useState<any>();
    const [subcategories, setSubcategories] = useState<any>([]);
    const [subcategory, setSubcategory] = useState<any>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const subjects = [
        { name: "Istorie", id: "22" },
        { name: "Matematică", id: "1" },
        { name: "Română", id: "4" },
        { name: "Fizică", id: "30" },
        { name: "Chimie", id: "34" },
        { name: "Biologie", id: "33" },
        { name: "Geografie", id: "37" },
    ];
    const [subject, setSubject] = useState("");
    const [grades, setGrades] = useState<any[]>([]);
    const [grade, setGrade] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [qData, setQData] = useState("");
    const [qObs, setQObs] = useState("");
    const [q1, setQ1] = useState("");
    const [q2, setQ2] = useState("");
    const [q3, setQ3] = useState("");
    const [q4, setQ4] = useState("");
    const quillRefQData = useRef();
    const quillRefQObs = useRef();
    const quillRefQ1 = useRef();
    const quillRefQ2 = useRef();
    const quillRefQ3 = useRef();
    const quillRefQ4 = useRef();

    useEffect(() => {
        setLoading(true);
        if (id !== "") {
            APIGetCustomItem({ id: id })
                .then((response) => {
                    if (response.data.success) {
                        // console.log("getCustomItem OK", response);
                        // api call to get disciplines
                        // on ok do following
                        if (response.data.data !== null) {
                            const item = {...response.data.data.item, is_active: response.data.data.item.is_active === 1}
                            setItem(item);
                            setSubject(response.data.data.item.subject.id);
                            setIsActive(response.data.data.item.is_active === 1);
                            const itemGrade = response.data.data.item.grade.id;
                            const itemSubcategory = response.data.data.item.subcategory.id;

                            APIPostGrades({ typeId: response.data.data.item.subject.id, testType: "school" })
                                .then((response) => {
                                    // console.log("getGrades Ok", response);
                                    if (response.data.success) {
                                        setGrades(response.data.data.items);
                                        setGrade(itemGrade);
                                    } else {
                                        update("error", { show: true, code: "A002" });
                                    }
                                })
                                .catch((err) => {
                                    // console.log("getGrades ERR", err);
                                    update("error", { show: true, code: "A001" });
                                });

                            APIPostCategories({ grade_id: itemGrade })
                                .then((response) => {
                                    if (response.data.success) {
                                        // console.log("getSubcategories Ok", response);
                                        setSubcategories(response.data.data.items);
                                        setSubcategory(itemSubcategory);
                                    } else {
                                        update("error", { show: true, code: "A002" });
                                    }
                                })
                                .catch((err) => {
                                    // console.log("getCategories ERR", err);
                                    update("error", { show: true, code: "A001" });
                                });

                        } else {
                            navigate("/pagina-nu-exista");
                        }
                        setLoading(false);
                    } else {
                        update("error", { show: true, code: "A002" });
                    }
                })
                .catch((err) => {
                    // console.log("getCustomItem ERR", err);
                    update("error", { show: true, code: "A001" });
                });
        } else {
            let locationArray = location.pathname.split("/");
            if (locationArray[locationArray.length - 1] === "adauga") {
                setItem({
                    subject: {id: ""},
                    subcategory: {id: ""},
                    item_type: "",
                    title: "",
                    right_answer: "",
                    is_active: true,
                    q_data: "",
                    q_obs: "",
                    q_1: "",
                    q_2: "",
                    q_3: "",
                    q_4: "",
                    grade: {id: ""},
                });
                setEditMode(true);
                setLoading(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (id !== "") {
            methods.clearErrors();
            setErrorMessage("");
            setEditMode(action === "editare");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, id]);

    const setSubjectHandler = (event: SelectChangeEvent) => {
        setGrade("");
        setSubcategory("");
        setSubject(event.target.value);
        APIPostGrades({ typeId: event.target.value, testType: "school" })
            .then((response) => {
                if (response.data.success) {
                    setGrades(response.data.data.items);
                } else {
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                // console.log("getGrades ERR", err);
                update("error", { show: true, code: "A001" });
            });
    }

    const setGradeHandler = (event: SelectChangeEvent) => {
        setSubcategory("");
        setGrade(event.target.value);

        APIPostCategories({ grade_id: event.target.value })
            .then((response) => {
                if (response.data.success) {
                    setSubcategories(response.data.data.items);
                } else {
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                // console.log("getCategories ERR", err);
                update("error", { show: true, code: "A001" });
            });
    }

    const setSubcategoryHandler = (event: SelectChangeEvent) => {
        setSubcategory(event.target.value);
    }

    const imageHandler = (refName: string, file: string) => {
        let quill;

        if(refName === "q_data") {
            quill = quillRefQData.current;
        }
        if(refName === 'q_obs') {
            quill = quillRefQObs.current;
        }
        if(refName === 'q_1') {
            quill = quillRefQ1.current;
        }
        if(refName === 'q_2') {
            quill = quillRefQ2.current;
        }
        if(refName === 'q_3') {
            quill = quillRefQ3.current;
        }
        if(refName === 'q_4') {
            quill = quillRefQ4.current;
        }

        if (quill) {
            // @ts-ignore
            const range = quill.getSelection();
            // @ts-ignore
            range && quill.insertEmbed(range.index, "image", file);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsActive(event.target.checked);
    }

    const onSubmit: SubmitHandler<IFormCustomItemsEdit> = (data) => {
        if(
            data.title !== item.title ||
            qData !== item.q_data ||
            qObs !== item.q_obs ||
            data.right_answer !== item.right_answer ||
            item.q_1 !== q1 ||
            item.q_2 !== q2 ||
            item.q_3 !== q3 ||
            item.q_4 !== q4 ||
            isActive !== item.is_active ||
            subject !== item.subject.id ||
            grade !== item.grade.id ||
            subcategory !== item.subcategory.id
        ) {
            methods.clearErrors();
            setErrorMessage("");
            update("loading", { show: true, modal: true });
            if(id !== "") {
                APIUpdateCustomItem(id, {
                    title: data.title,
                    q_data: qData,
                    q_obs: qObs,
                    right_answer: data.right_answer,
                    q_1: q1,
                    q_2: q2,
                    q_3: q3,
                    q_4: q4,
                    is_active: isActive,
                    subject: subject,
                    grade: grade,
                    subcategory: subcategory
                })
                    .then((response) => {
                        if (response.data.success) {
                            // console.log("updateCustomItem OK", response);
                            // logic after update
                            setItem(response.data.data.item);
                            setEditMode(false);
                            navigate(-1);
                            update("loading", { show: false });
                        } else {
                            update("loading", { show: false });
                            update("error", { show: true, code: "A002" });
                        }
                    })
                    .catch((err) => {
                        // console.log("updateCustomItem ERR", err);
                        if (err.response.status === 400) {
                            let errors = err.response.data.data;
                            if (errors.title) {
                                methods.setError("title", { type: "server", message: errors.title[0] });
                            }
                            if (errors.q_data) {
                                methods.setError("q_data", { type: "server", message: errors.q_data[0] });
                            }
                            if (errors.q_obs) {
                                methods.setError("q_obs", { type: "server", message: errors.q_obs[0] });
                            }
                            if (errors.right_answer) {
                                methods.setError("right_answer", { type: "server", message: errors.right_answer[0] });
                            }
                            if (errors.q_1) {
                                methods.setError("q_1", { type: "server", message: errors.q_1[0] });
                            }
                            if (errors.q_2) {
                                methods.setError("q_2", { type: "server", message: errors.q_2[0] });
                            }
                            if (errors.q_3) {
                                methods.setError("q_3", { type: "server", message: errors.q_3[0] });
                            }
                            if (errors.q_4) {
                                methods.setError("q_4", { type: "server", message: errors.q_4[0] });
                            }
                            if (errors.duration) {
                                methods.setError("duration", { type: "server", message: errors.duration[0] });
                            }
                            if (errors.is_active) {
                                methods.setError("is_active", { type: "server", message: errors.is_active[0] });
                            }
                            if (errors.subject) {
                                methods.setError("subject", { type: "server", message: errors.subject[0] });
                            }
                            if (errors.grade) {
                                methods.setError("grade", { type: "server", message: errors.grade[0] });
                            }
                            if (errors.subcategory) {
                                methods.setError("subcategory", { type: "server", message: errors.subcategory[0] });
                            }
                            update("loading", { show: false });
                        } else {
                            update("error", { show: true, code: "A001" });
                        }
                    });
            } else {
                APIPostCustomItem({
                    title: data.title,
                    q_data: qData,
                    q_obs: qObs,
                    right_answer: data.right_answer,
                    q_1: q1,
                    q_2: q2,
                    q_3: q3,
                    q_4: q4,
                    is_active: isActive,
                    subject: subject,
                    grade: grade,
                    subcategory: subcategory
                })
                    .then((response) => {
                        if (response.data.success) {
                            // console.log("postCustomItem OK", response);
                            // logic after update
                            setItem(
                                {title: data.title,
                                q_data: data.q_data,
                                q_obs: data.q_obs,
                                right_answer: data.right_answer,
                                q_1: data.q_1,
                                q_2: data.q_2,
                                q_3: data.q_3,
                                q_4: data.q_4,
                                duration: 23,
                                is_active: isActive,
                                subject: subject,
                                grade: grade,
                                subcategory: subcategory});
                            setEditMode(false);
                            navigate(`/itemi-proprii/${response.data.data.item.id}`, { replace: true });
                            update("loading", { show: false });
                        } else {
                            update("loading", { show: false });
                            update("error", { show: true, code: "A002" });
                        }
                    })
                    .catch((err) => {
                        if (err.response.status === 400) {
                            let errors = err.response.data.data;
                            if (errors.title) {
                                methods.setError("title", { type: "server", message: errors.title[0] });
                            }
                            if (errors.q_data) {
                                methods.setError("q_data", { type: "server", message: errors.q_data[0] });
                            }
                            if (errors.q_obs) {
                                methods.setError("q_obs", { type: "server", message: errors.q_obs[0] });
                            }
                            if (errors.right_answer) {
                                methods.setError("right_answer", { type: "server", message: errors.right_answer[0] });
                            }
                            if (errors.q_1) {
                                methods.setError("q_1", { type: "server", message: errors.q_1[0] });
                            }
                            if (errors.q_2) {
                                methods.setError("q_2", { type: "server", message: errors.q_2[0] });
                            }
                            if (errors.q_3) {
                                methods.setError("q_3", { type: "server", message: errors.q_3[0] });
                            }
                            if (errors.q_4) {
                                methods.setError("q_4", { type: "server", message: errors.q_4[0] });
                            }
                            if (errors.duration) {
                                methods.setError("duration", { type: "server", message: errors.duration[0] });
                            }
                            if (errors.is_active) {
                                methods.setError("is_active", { type: "server", message: errors.is_active[0] });
                            }
                            if (errors.subject) {
                                methods.setError("subject", { type: "server", message: errors.subject[0] });
                            }
                            if (errors.grade) {
                                methods.setError("grade", { type: "server", message: errors.grade[0] });
                            }
                            if (errors.subcategory) {
                                methods.setError("subcategory", { type: "server", message: errors.grade[0] });
                            }
                            update("loading", { show: false });
                        } else {
                            update("error", { show: true, code: "A001" });
                        }
                    });
            }
        } else {
            setErrorMessage("Nu ați modificat niciun câmp.");
        }
    };

    return (
        <Box component="main">
            {(loading) ? (
                <Loading show={true} />
            ) : (
                <>
                    <PageTitle
                        back={true}
                        actions={
                            user.position === "teacher" ? (
                                editMode ? (
                                    <GenericButton
                                        type="close"
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                    />
                                ) : (
                                    <GenericButton
                                        type="edit"
                                        onClick={() => {
                                            navigate(`/itemi-proprii/${id}/editare`);
                                        }}
                                    />
                                )
                                ) : (
                                    ""
                            )

                        }
                    >
                        {id !== "" ? (
                            <>
                                Item{" "}
                                <strong style={{ fontWeight: 400 }}>
                                    {item.id}
                                </strong>
                            </>
                        ) : (
                            <>Adaugă item</>
                        )}
                    </PageTitle>

                    <BoxContent>
                        <Box width="100%">
                            {!editMode ? (
                                <>
                                    <TestItem item={item} itemPreview={true}/>
                                </>

                            ) : (
                                <FormProvider {...methods}>
                                    <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                                        <Grid container spacing={1.5} alignItems="center">
                                            <FormLabelBox title="Materie:" />
                                            <FormFieldBox>
                                                <FormField
                                                    name="subject"
                                                    type="controlled-select"
                                                    label="Alege materia"
                                                    value={subject}
                                                    options={subjects.map((item: { name: string; id: string }) => {
                                                        return {
                                                            label: item.name,
                                                            value: item.id,
                                                            ...item,
                                                        };
                                                    })}
                                                    errorMessage={methods.formState.errors.subject?.message}
                                                    error={!!methods.formState.errors.subject?.message}
                                                    onChange={setSubjectHandler}
                                                />
                                            </FormFieldBox>
                                            <FormLabelBox title="Clasă:" />

                                            <FormFieldBox>
                                                <FormField
                                                    name="grade"
                                                    type="controlled-select"
                                                    label="Alege clasa"
                                                    disabled={!subject}
                                                    value={grade}
                                                    options={grades.map((item: { name: string; id: string }) => {
                                                        return {
                                                            label: item.name,
                                                            value: item.id,
                                                            ...item,
                                                        };
                                                    })}
                                                    errorMessage={methods.formState.errors.grade?.message}
                                                    error={!!methods.formState.errors.grade?.message}
                                                    onChange={setGradeHandler}
                                                />
                                            </FormFieldBox>
                                            <FormLabelBox title="Subcategorie:" />

                                            <FormFieldBox>
                                                <FormField
                                                    name="subcategory"
                                                    type="controlled-select"
                                                    label="Alege subcategoria"
                                                    multiple={false}
                                                    value={subcategory}
                                                    disabled={!grade}
                                                    options={subcategories.map(
                                                        (item: { name: string; id: string; subcategories: []; [key: string]: any }) => {
                                                            return {
                                                                label: item.name,
                                                                value: item.id,
                                                                id: item.id,
                                                                name: item.name,
                                                                subcategories: item.subcategories.map(
                                                                    (subcategory: { name: string; id: string; [key: string]: any }) => {
                                                                        return {
                                                                            label: subcategory.name,
                                                                            value: subcategory.id.toString(),
                                                                            ...subcategory,
                                                                        };
                                                                    },
                                                                ),
                                                            };
                                                        },
                                                    )}
                                                    errorMessage={methods.formState.errors.subcategory?.message}
                                                    error={!!methods.formState.errors.subcategory?.message}
                                                    onChange={setSubcategoryHandler}
                                                    disableClearable={true}
                                                />
                                            </FormFieldBox>

                                            <FormLabelBox title="Răspuns corect:" />

                                            <FormFieldBox>
                                                <FormField
                                                    name="right_answer"
                                                    type="select"
                                                    defaultValue={item.right_answer}
                                                    label="Alege răspunsul corect"
                                                    options={[
                                                        {label: "a", value: "a"},
                                                        {label: "b", value: "b"},
                                                        {label: "c", value: "c"},
                                                        {label: "d", value: "d"},
                                                    ]}
                                                    errorMessage={methods.formState.errors.right_answer?.message}
                                                    error={!!methods.formState.errors.right_answer?.message}
                                                />
                                            </FormFieldBox>

                                            <FormLabelBox title="Titlu:" />

                                            <FormFieldBox>
                                                <FormField
                                                    name="title"
                                                    type="text"
                                                    defaultValue={item.title}
                                                    label="Titlu"
                                                    errorMessage={methods.formState.errors.title?.message}
                                                    error={!!methods.formState.errors.title?.message}
                                                />
                                            </FormFieldBox>

                                            <FormLabelBox title="Enunț:" />
                                            <FormFieldBox>
                                                <CustomTextEditor
                                                    ref={quillRefQData}
                                                    name="q_data"
                                                    defaultValue={item.q_data}
                                                    imageHandler={imageHandler}
                                                    passText={setQData}
                                                    errorMessage={methods.formState.errors.q_data?.message}
                                                    clearErrors={methods.clearErrors}
                                                />
                                            </FormFieldBox>

                                            <FormLabelBox title="Cerință:" />
                                            <FormFieldBox>
                                                <CustomTextEditor
                                                    ref={quillRefQObs}
                                                    name="q_obs"
                                                    defaultValue={item.q_obs}
                                                    imageHandler={imageHandler}
                                                    passText={setQObs}
                                                    errorMessage={methods.formState.errors.q_obs?.message}
                                                    clearErrors={methods.clearErrors}
                                                />
                                            </FormFieldBox>

                                            <FormLabelBox title="a:" />
                                            <FormFieldBox>
                                                <CustomTextEditor
                                                    ref={quillRefQ1}
                                                    name="q_1"
                                                    defaultValue={item.q_1}
                                                    imageHandler={imageHandler}
                                                    passText={setQ1}
                                                    errorMessage={methods.formState.errors.q_1?.message}
                                                    clearErrors={methods.clearErrors}
                                                />
                                            </FormFieldBox>

                                            <FormLabelBox title="b:" />
                                            <FormFieldBox>
                                                <CustomTextEditor
                                                    ref={quillRefQ2}
                                                    name="q_2"
                                                    defaultValue={item.q_2}
                                                    imageHandler={imageHandler}
                                                    passText={setQ2}
                                                    errorMessage={methods.formState.errors.q_2?.message}
                                                    clearErrors={methods.clearErrors}
                                                />
                                            </FormFieldBox>

                                            <FormLabelBox title="c:" />
                                            <FormFieldBox>
                                                <CustomTextEditor
                                                    ref={quillRefQ3}
                                                    name="q_3"
                                                    defaultValue={item.q_3}
                                                    imageHandler={imageHandler}
                                                    passText={setQ3}
                                                    errorMessage={methods.formState.errors.q_3?.message}
                                                    clearErrors={methods.clearErrors}
                                                />
                                            </FormFieldBox>

                                            <FormLabelBox title="d:" />
                                            <FormFieldBox>
                                                <CustomTextEditor
                                                    ref={quillRefQ4}
                                                    name="q_4"
                                                    defaultValue={item.q_4}
                                                    imageHandler={imageHandler}
                                                    passText={setQ4}
                                                    errorMessage={methods.formState.errors.q_4?.message}
                                                    clearErrors={methods.clearErrors}
                                                />
                                            </FormFieldBox>

                                            <FormLabelBox title="Item activ?" />
                                            <FormFieldBox>
                                                <CheckBoxField
                                                    checked={isActive}
                                                    onChange={handleChange}
                                                    name="is_active"
                                                    defaultValue={item.is_active}
                                                    errorMessage={methods.formState.errors.is_active?.message}
                                                />
                                            </FormFieldBox>
                                                <FormLabelBox />
                                                <FormFieldBox>
                                                    <CustomButton submit buttonType="textIcon" bgcolor="dark">
                                                        {id !== "" ? "SALVEAZĂ DATELE" : "ADAUGĂ ITEM"}
                                                    </CustomButton>
                                                    <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
                                                </FormFieldBox>
                                        </Grid>
                                    </form>
                                </FormProvider>
                            )}

                        </Box>
                    </BoxContent>
                </>
            )}
        </Box>
    );
};

export default CustomTestItem;
