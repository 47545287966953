import React, {useEffect, useState} from "react";
import {APIDeleteMedia, APIGetMedia, APIPostMedia} from "../../../api/pages/media";
import {useAuth} from "../../../hooks/useAuth";
import Loading from "../Loading";
import Box from "@mui/material/Box";
import UploadButton from "../../form/UploadButton";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import ErrorsList from "../ErrorsList";
import DialogActions from "@mui/material/DialogActions";
import CustomButton from "../CustomButton";
import BoxContent from "../BoxContent";
import GenericButton from "../GenericButton";
import ImageListItem from "@mui/material/ImageListItem";
import ImageList from "@mui/material/ImageList";
import useMediaQuery from "@mui/material/useMediaQuery";

interface ICustomTextEditorImages {
    okHandler: () => void;
    closeHandler: () => void;
    selectImageHandler: any;
    selectedFile: any;
}

const CustomTextEditorImages = ({okHandler, closeHandler, selectImageHandler, selectedFile}: ICustomTextEditorImages) => {
    const { update } = useAuth();
    const [loading, setLoading] = useState(true);
    const [images, setImages] = useState<any>();
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState(0);
    const [error, setError] = useState("");
    const matches = useMediaQuery('(min-width:600px)');

    const getMediaHandler = () => {
        setLoading(true);
        APIGetMedia({ page: `?page=${page}`, sort: "created_at", order: "desc" })
            .then((response) => {
                if (response.data.success) {
                    // console.log("getMedia OK", response);
                    const itemData = response.data.data.data;
                    setImages(itemData);
                    setTotalPages(response.data.data.last_page);
                    setLoading(false);
                } else {
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                // console.log("getCustomItem ERR", err);
                update("error", { show: true, code: "A001" });
            });
    }

    useEffect(() => {
        getMediaHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        console.log(value)
        setPage(value);
    };

    const uploadFile = (file: File | null, fileUrl: string, name: string) => {
        setError("");
        const formData = new FormData();
        file && formData.append("file", file);

        if(name === "file") {
            APIPostMedia(formData)
                .then((response) => {
                    if (response.data.success) {
                        // console.log("Post Media OK", response);
                        getMediaHandler();
                    } else {
                        update("error", { show: true, code: "A002" });
                    }
                })
                .catch((err) => {
                    // console.log("Error Post Media", err);
                    if (err.response.status === 400) {
                        let errors = err.response.data.data.message;
                        setError(errors);
                    } else {
                        update("error", { show: true, code: "A001" });
                    }
                })
        }
    };


    const deleteImage = (id: string, callback: Function) => {
        update("loading", { show: true, modal: true });
        APIDeleteMedia(id)
            .then((response) => {
                if (response.data.success) {
                    // console.log("deleteTeacher OK", response);
                    update("loading", { show: false });
                    update("media", {
                        show: false,
                    });
                } else {
                    update("loading", { show: false });
                    update("error", { show: true, code: "A002" });
                }
                callback();
            })
            .catch((err) => {
                // console.log("deleteTeacher ERR", err);
                update("loading", { show: false });
                update("error", { show: true, code: "A001" });
            });
    };

    const confirmDelete = (id: number) => {
        update("confirm", {
            show: true,
            closable: true,
            title: "Ești sigur că vrei să ștergi această imagine?",
            message: (
                <>
                    Acțiunea va șterge definitiv imaginea selectată!
                </>
            ),
            returnFunction: (callback: Function) => {
                deleteImage(id.toString(), callback);
            },
            buttonYes: "Șterge",
            buttonNo: "Închide",
        });
    };

    return (
        <>
            <Box display="flex" flexWrap="wrap" justifyContent="space-between" mb={2} pr={2} pl={2}>
                <Box mb={{xs: 1, sm: 0}}>
                    <GenericButton disabled={!selectedFile[0]} type="delete" onClick={() => confirmDelete(selectedFile[0].id)} />
                </Box>
                <UploadButton
                    name="file"
                    type="button"
                    label="Imagine"
                    defaultValue=""
                    fileType="image"
                    updateFileCb={uploadFile}
                />
            </Box>

            <DialogContent sx={(theme) => ({ mb: 3, p: theme.spacing(0, 2, 0), minHeight: {xs: "auto", lg: "300px"}})}>
                <BoxContent>
                    {loading ? <Loading show={true} /> : (
                        <>
                            <ImageList cols={matches ? 5 : 1}>
                                {images.length !== 0 ? images.map((item: any) => (
                                    <button
                                        key={item.file}
                                        onClick={() => selectImageHandler(item)}
                                        style={{
                                            padding: 4,
                                            backgroundColor: "rgba(255, 255, 255, 0.5)",
                                            border: selectedFile[0] !== null ? selectedFile[0].id === item.id ? "2px solid #0064FF" : "2px solid rgba(255, 255, 255, 0.5)": "2px solid rgba(255, 255, 255, 0.5)",
                                            borderRadius: "5px",
                                            overflow: "hidden",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <ImageListItem>
                                            <img
                                                srcSet={`${item.file}`}
                                                src={`${item.file}`}
                                                alt={item.id}
                                                loading="lazy"
                                                style={{borderRadius: "5px"}}
                                            />
                                        </ImageListItem>
                                    </button>
                                )) : <Typography>Nu sunt imagini adaugate</Typography>}
                            </ImageList>
                            <Pagination count={totalPages} page={page} onChange={handlePageChange} />
                        </>
                    )
                    }
                </BoxContent>

            </DialogContent>
            <Box mb={2} pl={2}>
                <Typography display={{xs: "none", md: "block"}} variant="body2">Tipuri de fișiere permise: jpg, jpeg, png. Fișierul nu poate depăși 5MB. </Typography>
                <ErrorsList show={error !== ""}>{error}</ErrorsList>
            </Box>

            <DialogActions sx={(theme) => ({ mb: 0, p: theme.spacing(0, 2, 3, 2), mt: -1 })}>
                <Box display="flex" gap={2} width="100%" alignItems="center" justifyContent="space-between">
                    <CustomButton buttonType="textIconOutlined" onClick={closeHandler} color="info.contrastText" borderColor="info.contrastText" bgcolor="modal">
                        Închide
                    </CustomButton>
                    <CustomButton disabled={!selectedFile[0]} buttonType="textIcon" onClick={okHandler} bgcolor="modal" style={{ fontWeight: 500 }}>
                        Adaugă
                    </CustomButton>
                </Box>
            </DialogActions>
        </>
    )

}

export default CustomTextEditorImages;