import React from "react";
import Icon from "components/ui/Icon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IConfirm, useAuth } from "hooks/useAuth";
import CustomButton from "./CustomButton";

const ConfirmModal = ({ confirm, children }: IConfirm) => {
  const { update } = useAuth();

  const closeHandler = () => {
    update("confirm", { show: false });
    confirm.cancelFunction?.();
  };

  const okHandler = () => {
    if (typeof confirm.returnFunction !== "undefined") {
      confirm.returnFunction(() => {
        update("confirm", {}, true);
      });
      update("confirm", {}, true);
    } else {
      update("confirm", {}, true);
    }
  };

  return confirm.show ? (
    <>
      <Dialog
        open={confirm.show}
        // onClose={closeHandler}
        onClose={() => {
          return confirm.closable ? closeHandler() : null;
        }}
        maxWidth={confirm.size === "small" ? "sm" : "lg"}
        PaperComponent={Paper}
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: "info.light",
            backgroundImage: "none",
            padding: theme.spacing(0, 1),
            width: confirm.size === "small" ? "auto" : "600px",
          }),
        }}
        sx={{ backgroundColor: "rgba(0, 40, 155, 0.5)" }}
      >
        <DialogTitle display="flex" alignItems="center" justifyContent="space-between" gap={1} sx={{ m: 0, p: 2 }}>
          <Box flexGrow={1} py={1}>
            <Typography variant="h3" color="info.contrastText">
              {confirm.title}
            </Typography>
          </Box>
          {confirm.closable && (
            <IconButton onClick={closeHandler} aria-label="close">
              <Icon icon="fal xmark" fixedWidth={true} sx={{ color: "primary.main" }} />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent sx={(theme) => ({ mb: 3, p: theme.spacing(0, 2, 0) })}>
          <Box>{confirm.message}</Box>
        </DialogContent>
        <DialogActions sx={(theme) => ({ mb: 0, p: theme.spacing(0, 2, 3, 2), mt: -1 })}>
          <Box display="flex" gap={2} width="100%" alignItems="center" justifyContent="space-between">
            {/*<Button variant="outlined" color="primary" onClick={closeHandler}>*/}
            {/*  {confirm.buttonNo || "NU"}*/}
            {/*</Button>*/}
            <CustomButton buttonType="textIconOutlined" onClick={closeHandler} color="info.contrastText" borderColor="info.contrastText" bgcolor="modal">
              {confirm.buttonNo || "NU"}
            </CustomButton>
            <CustomButton buttonType="textIcon" onClick={okHandler} bgcolor="modal" style={{ fontWeight: 500 }}>
              {confirm.buttonYes || "DA"}
            </CustomButton>
            {/*<Button variant="contained" color="primary" onClick={okHandler} style={{ fontWeight: 500 }}>*/}
            {/*  {confirm.buttonYes || "DA"}*/}
            {/*</Button>*/}
          </Box>
        </DialogActions>
      </Dialog>
      {children}
    </>
  ) : null;
};

export default ConfirmModal;
