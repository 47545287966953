import RegisterSchoolTeachersForm from "../../components/login/RegisterSchoolTeachersForm";
import LinkStyled from "../../components/ui/LinkStyled";
import React from "react";

const RegisterSchoolTeachers = () => {
    return (
        <>
            <RegisterSchoolTeachersForm />
            <LinkStyled to="/">Autentificare</LinkStyled>
        </>
    );
}

export default RegisterSchoolTeachers;