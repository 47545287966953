import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import BoxContent from "components/ui/BoxContent";
import PageTitle from "components/ui/PageTitle";
import { APIGetAddress } from "api/pages/address";
import Loading from "components/ui/Loading";
import { useAuth } from "hooks/useAuth";
import { APIUpdateAddress, APIPostAddress } from "api/pages/address";
import FormField from "components/form/FormField";
import { useLocation } from "react-router-dom";
import CheckBoxField from "components/form/CheckBoxField";
import ErrorsList from "components/ui/ErrorsList";
import FormLabelBox from "components/ui/FormLabelBox";
import FormFieldBox from "components/ui/FormFieldBox";
import GenericButton from "components/ui/GenericButton";
import CustomButton from "../../components/ui/CustomButton";

interface IFormAddressEdit {
  name: string;
  phone: string;
  city: string;
  county: string;
  country: string;
  line1: string;
  postal_code: string;
  isCompany: boolean;
  company_name?: string;
  company_fiscal_code?: string;
  company_registration?: string;
}

const schema = yup
  .object({
  })
  .required();

const Address = () => {
  const { id = "", action } = useParams();
  const { update } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const methods = useForm<IFormAddressEdit>({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(true);
  const [address, setAddress] = useState<any>();
  const [isCompany, setIsCompany] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (id !== "") {
      setLoading(true);
      APIGetAddress({ id: id })
        .then((response) => {
          if (response.data.success) {
            setAddress(response.data.data.item);
            setIsCompany(response.data.data.item.is_company);
            setLoading(false);
          } else {
            update("error", { show: true, code: "A002" });
          }
        })
        .catch((err) => {
          update("error", { show: true, code: "A001" });
        });
    } else {
      let locationArray = location.pathname.split("/");
      if (locationArray[locationArray.length - 1] === "adauga") {
        setAddress({
          name: "",
          phone: "",
          city: "",
          county: "",
          country: "",
          line1: "",
          postal_code: "",
          is_company: false,
          company_name: undefined,
          company_fiscal_code: undefined,
          company_registration: undefined,
        });
        setEditMode(true);
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id !== "") {
      methods.clearErrors();
      setEditMode(action === "editare");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, id]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCompany(event.target.checked);
  };

  const onSubmit: SubmitHandler<IFormAddressEdit> = (data) => {
    if (
      data.name !== address.name ||
      data.phone !== address.phone ||
      data.city !== address.city ||
      data.county !== address.county ||
      data.country !== address.country ||
      data.line1 !== address.line1 ||
      data.postal_code !== address.postal_code ||
      isCompany !== address.is_company ||
      data.company_name !== address.company_name ||
      data.company_fiscal_code !== address.company_fiscal_code ||
      data.company_registration !== address.company_registration
    ) {
      methods.clearErrors();
      setErrorMessage("");
      update("loading", { show: true, modal: true });
      if (id !== "") {
        APIUpdateAddress(id, {
          ...{ name: data.name },
          ...{ phone: data.phone },
          ...{ city: data.city },
          ...{ county: data.county },
          ...{ country: data.country },
          ...{ line1: data.line1 },
          ...{ postal_code: data.postal_code },
          ...{ is_company: isCompany },
          ...{ company_name: data.company_name },
          ...{ company_fiscal_code: data.company_fiscal_code },
          ...{ company_registration: data.company_registration },
        })
          .then((response) => {
            if (response.data.success) {
              // logic after update
              setAddress(data);
              setEditMode(false);
              navigate(-1);
              update("loading", { show: false });
            } else {
              update("loading", { show: false });
              update("error", { show: true, code: "A002" });
            }
          })
          .catch((err) => {
            if (err.response.status === 400) {
              let errors = err.response.data.data;

              if (errors.name) {
                methods.setError("name", { type: "server", message: errors.name[0] });
              }
              if (errors.phone) {
                methods.setError("phone", { type: "server", message: errors.phone[0] });
              }
              if (errors.city) {
                methods.setError("city", { type: "server", message: errors.city[0] });
              }
              if (errors.county) {
                methods.setError("county", { type: "server", message: errors.county[0] });
              }
              if (errors.country) {
                methods.setError("country", { type: "server", message: errors.country[0] });
              }
              if (errors.line1) {
                methods.setError("line1", { type: "server", message: errors.line1[0] });
              }
              if (errors.postal_code) {
                methods.setError("postal_code", { type: "server", message: errors.postal_code[0] });
              }
              if (errors.company_name) {
                methods.setError("company_name", { type: "server", message: errors.company_name[0] });
              }
              if (errors.company_fiscal_code) {
                methods.setError("company_fiscal_code", { type: "server", message: errors.company_fiscal_code[0] });
              }
              if (errors.company_registration) {
                methods.setError("company_registration", { type: "server", message: errors.company_registration[0] });
              }
              update("loading", { show: false });
            } else {
              update("error", { show: true, code: "A001" });
            }
          });
      } else {
        APIPostAddress({
          name: data.name,
          phone: data.phone,
          city: data.city,
          county: data.county,
          country: data.country,
          line1: data.line1,
          postal_code: data.postal_code,
          is_company: isCompany,
          company_name: data.company_name,
          company_fiscal_code: data.company_fiscal_code,
          company_registration: data.company_registration,
        })
          .then((response) => {
            if (response.data.success) {
              // logic after update
              setAddress(data);
              setEditMode(false);
              navigate(-1);
              update("loading", { show: false });
            } else {
              update("loading", { show: false });
              update("error", { show: true, code: "A002" });
            }
          })
          .catch((err) => {
            if (err.response.status === 400) {
              let errors = err.response.data.data;

              if (errors.name) {
                methods.setError("name", { type: "server", message: errors.name[0] });
              }
              if (errors.phone) {
                methods.setError("phone", { type: "server", message: errors.phone[0] });
              }
              if (errors.city) {
                methods.setError("city", { type: "server", message: errors.city[0] });
              }
              if (errors.county) {
                methods.setError("county", { type: "server", message: errors.county[0] });
              }
              if (errors.country) {
                methods.setError("country", { type: "server", message: errors.country[0] });
              }
              if (errors.line1) {
                methods.setError("line1", { type: "server", message: errors.line1[0] });
              }
              if (errors.postal_code) {
                methods.setError("postal_code", { type: "server", message: errors.postal_code[0] });
              }
              if (errors.company_name) {
                methods.setError("company_name", { type: "server", message: errors.company_name[0] });
              }
              if (errors.company_fiscal_code) {
                methods.setError("company_fiscal_code", { type: "server", message: errors.company_fiscal_code[0] });
              }
              if (errors.company_registration) {
                methods.setError("company_registration", { type: "server", message: errors.company_registration[0] });
              }

              update("loading", { show: false });
            } else {
              update("error", { show: true, code: "A001" });
            }
          });
      }
    } else {
      setErrorMessage("Nu ați modificat niciun câmp.");
    }
  };

  return (
    <Box component="main">
      <PageTitle
        back={true}
        actions={
          editMode ? (
            <GenericButton
              type="close"
              onClick={() => {
                navigate(-1);
              }}
            />
          ) : (
            <GenericButton
              type="edit"
              onClick={() => {
                navigate(`/profil/adrese/${id}/editare`);
              }}
            />
          )
        }
      >
        {id !== "" ? <>Editează adresa</> : <>Adaugă adresă</>}
      </PageTitle>

      <BoxContent>
        {loading ? (
          <Loading show={true} />
        ) : (
          <Box width="100%">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                <Grid container spacing={1.5} alignItems="center">
                  <FormLabelBox title="Nume:" />
                  <FormFieldBox>
                    {!editMode ? (
                      address.name
                    ) : (
                      <FormField
                        name="name"
                        type="text"
                        defaultValue={address.name}
                        label="Nume"
                        autoFocus={true}
                        errorMessage={methods.formState.errors.name?.message}
                      />
                    )}
                  </FormFieldBox>
                  <FormLabelBox title="Telefon:" />
                  <FormFieldBox>
                    {!editMode ? (
                      address.phone
                    ) : (
                      <FormField
                        name="phone"
                        type="text"
                        defaultValue={address.phone}
                        label="Telefon"
                        errorMessage={methods.formState.errors.phone?.message}
                      />
                    )}
                  </FormFieldBox>
                  <FormLabelBox title="Oraș:" />
                  <FormFieldBox>
                    {!editMode ? (
                      address.city
                    ) : (
                      <FormField
                        name="city"
                        type="text"
                        defaultValue={address.city}
                        label="Oraș"
                        errorMessage={methods.formState.errors.city?.message}
                      />
                    )}
                  </FormFieldBox>
                  <FormLabelBox title="Județ:" />
                  <FormFieldBox>
                    {!editMode ? (
                      address.county
                    ) : (
                      <FormField
                        name="county"
                        type="text"
                        defaultValue={address.county}
                        label="Județ"
                        errorMessage={methods.formState.errors.county?.message}
                      />
                    )}
                  </FormFieldBox>
                  <FormLabelBox title="Țară:" />
                  <FormFieldBox>
                    {!editMode ? (
                      address.country
                    ) : (
                      <FormField
                        name="country"
                        type="text"
                        defaultValue={address.country}
                        label="Țară"
                        errorMessage={methods.formState.errors.country?.message}
                      />
                    )}
                  </FormFieldBox>
                  <FormLabelBox title="Stradă:" />
                  <FormFieldBox>
                    {!editMode ? (
                      address.line1
                    ) : (
                      <FormField
                        name="line1"
                        type="text"
                        defaultValue={address.line1}
                        label="Stradă"
                        errorMessage={methods.formState.errors.line1?.message}
                      />
                    )}
                  </FormFieldBox>
                  <FormLabelBox title="Cod poștal:" />
                  <FormFieldBox>
                    {!editMode ? (
                      address.postal_code
                    ) : (
                      <FormField
                        name="postal_code"
                        type="text"
                        defaultValue={address.postal_code}
                        label="Cod poștal"
                        errorMessage={methods.formState.errors.postal_code?.message}
                      />
                    )}
                  </FormFieldBox>
                  <FormLabelBox title="Companie?" />
                  <FormFieldBox>
                    {!editMode ? (
                      address.is_company
                    ) : (
                      <CheckBoxField
                        checked={isCompany}
                        onChange={handleChange}
                        name="is_company"
                        defaultValue={address.is_company}
                        errorMessage={methods.formState.errors.isCompany?.message}
                      />
                    )}
                  </FormFieldBox>
                  {isCompany && (
                    <>
                      <FormLabelBox title="Nume companie:" />
                      <FormFieldBox>
                        {!editMode ? (
                          address.company_name
                        ) : (
                          <FormField
                            name="company_name"
                            type="text"
                            defaultValue={address.company_name}
                            label="Nume companie"
                            errorMessage={methods.formState.errors.company_name?.message}
                          />
                        )}
                      </FormFieldBox>
                      <FormLabelBox title="CUI:" />
                      <FormFieldBox>
                        {!editMode ? (
                          address.company_fiscal_code
                        ) : (
                          <FormField
                            name="company_fiscal_code"
                            type="text"
                            defaultValue={address.company_fiscal_code}
                            label="CUI"
                            errorMessage={methods.formState.errors.company_fiscal_code?.message}
                          />
                        )}
                      </FormFieldBox>
                      <FormLabelBox title="Număr înregistrare:" />
                      <FormFieldBox>
                        {!editMode ? (
                          address.company_registration
                        ) : (
                          <FormField
                            name="company_registration"
                            type="text"
                            defaultValue={address.company_registration}
                            label="Număr înregistrare"
                            errorMessage={methods.formState.errors.company_registration?.message}
                          />
                        )}
                      </FormFieldBox>
                    </>
                  )}

                  {editMode && (
                    <>
                      <FormLabelBox />
                      <FormFieldBox>
                        <CustomButton submit buttonType="textIcon" bgcolor="dark">
                          {id !== "" ? "SALVEAZĂ ADRESA" : "ADAUGĂ ADRESA"}
                        </CustomButton>
                        <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
                      </FormFieldBox>
                    </>
                  )}
                </Grid>
              </form>
            </FormProvider>
          </Box>
        )}
      </BoxContent>
    </Box>
  );
};

export default Address;
