import SchoolReportForm from "../../components/form/SchoolReportForm";
import React, {useEffect, useState} from "react";
import PageTitle from "../../components/ui/PageTitle";
import GenericButton from "../../components/ui/GenericButton";
import BoxContent from "../../components/ui/BoxContent";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import {APIPostReports} from "../../api/pages/schoolReports";
import {useAuth} from "../../hooks/useAuth";
import {SubmitHandler, useForm, UseFormReturn} from "react-hook-form";
import moment from "moment/moment";
import {ApiGetAllTags} from "../../api/pages/testTags";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import ErrorsList from "../../components/ui/ErrorsList";
import FormLabelBox from "../../components/ui/FormLabelBox";
import FormFieldBox from "../../components/ui/FormFieldBox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export interface IFormInputs {
    interval: string;
    tag: any[];
    subject: string;
}

const schema = yup.object({
    keywords: yup.string(),
});

export interface ISearchForm {
    onSubmit: (data: any) => void;
    startDate: Date | undefined;
    endDate: Date | undefined;
    onDateRangeChange: (dates: any) => void;
    tags: any[];
    tag: any[];
    methods:  UseFormReturn<IFormInputs, object>;
}

const SchoolReportGenerate = () => {
    const navigate = useNavigate();
    const { update } = useAuth();
    const [tags, setTags] = useState<any[]>([]);
    const [tag, setTag] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [errorMessage, setErrorMessage] = useState("");

    const methods = useForm<IFormInputs>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        ApiGetAllTags()
            .then((response) => {
                if (response.data.success) {
                    setTags(response.data.data.values);
                    // setLoading(false);
                } else {
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                update("error", { show: true, code: "A001" });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDateRangeChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        methods.clearErrors("interval");
    };

    const onSubmit: SubmitHandler<IFormInputs> = (data) => {
        methods.clearErrors();
        setErrorMessage("");
        const subjectData = [
            { label: "Istorie", value: "22", item_type_id: 22, grades: [50, 51, 52, 53, 54, 55, 56, 57] },
            { label: "Matematică", value: "1", item_type_id: 1, grades: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
            { label: "Română", value: "4", item_type_id: 4, grades: [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30] },
            { label: "Fizică", value: "30", item_type_id: 30, grades: [123, 124, 125, 126, 127, 128]},
            { label: "Chimie", value: "34", item_type_id: 34, grades: [150, 151, 152, 153, 154]},
            { label: "Biologie", value: "33", item_type_id: 33, grades: [136, 137, 138, 139, 140, 141, 142]},
            { label: "Geografie", value: "37", item_type_id: 37, grades: [162, 163, 164, 165, 166, 167, 168, 169]},
            {label: "Bac Mate/Științe", value: "21", subject: "Bac Mate/Științe", item_type_id: 21, grades: [901]},
            {label: "Bac Mate-Info", value: "212", subject: "Bac Mate-Info", item_type_id: 21, grades: [902]},
            {label: "Bac Română", value: "213", subject: "Bac Română", item_type_id: 21, grades: [903]},
            {label: "Evaluare nationala romana", value: "20", subject: "Evaluare nationala romana", item_type_id: 20, grades: [17]},
            {label: "Evaluare nationala matematica", value: "201", subject: "Evaluare nationala matematica", item_type_id: 20, grades: [48]},
            {label: "Literație", value: "19", subject: "Literație", item_type_id: 19, grades: [41, 42, 43, 44, 45, 46]},
            {label: "Literație digitală", value: "24", subject: "Literație digitală", item_type_id: 24, grades: [70, 71, 72]},
        ];
        const selectedSubjectArray = subjectData.filter((subject) => subject.value === data.subject);
        let selectedSubject = undefined;
        let itemTypeId = undefined;
        let grades: any[] = [];
        if( selectedSubjectArray.length !== 0) {
            selectedSubject = selectedSubjectArray[0].label;
            itemTypeId = selectedSubjectArray[0].item_type_id;
            grades = selectedSubjectArray[0].grades;
        }
        const selectedTags = data.tag.map((item : {value: string} ) => item.value);


        if(
            selectedTags.length !== 0 ||
            selectedSubject !== undefined ||
            startDate !== undefined ||
            endDate !== undefined
        ) {
            update("loading", { show: true, modal: true });
            APIPostReports({
                tag: selectedTags,
                subject: selectedSubject,
                item_type_id: itemTypeId,
                grades: grades,
                start_date: startDate ? moment(startDate).locale("ro").format("YYYY-MM-DD HH:mm:ss") : "",
                end_date: endDate ? moment(endDate).locale("ro").format("YYYY-MM-DD HH:mm:ss") : "",
            })
                .then((response) => {
                    if (response.data.success) {
                        update("error", {
                            show: true,
                            closable: true,
                            title: "Raportul este în curs de generare",
                            message: "Raportul tău BRIO va fi disponibil în pagina Rapoarte.",
                            icon: "fat face-smile",
                            button: "ÎNCHIDE",
                            returnFunction: () => {
                                navigate(-1);
                            },
                        });
                        update("loading", { show: false, modal: true });
                    } else {
                        update("error", { show: true, code: "A002" });
                    }
                })
                .catch((err) => {
                    console.log(err)
                    if (err.response.status === 400) {
                        let errors = err.response.data.data;
                        if (errors.end_date) {
                            methods.setError("interval", { type: "server", message: errors.end_date[0] });
                        }
                        if (errors.tag) {
                            methods.setError("tag", { type: "server", message: errors.tag[0] });
                        }
                        if (errors.subject) {
                            methods.setError("subject", { type: "server", message: errors.subject[0] });
                        }
                        if(errors.error) {
                            setErrorMessage(errors.error);
                        }
                        update("loading", { show: false });
                    } else {
                        update("error", { show: true, code: "A001" });
                    }
                });
        } else {
            setErrorMessage("Nu ați modificat niciun câmp.");
        }


    }

    return <Box component="main">
        <PageTitle
            back={true}
            actions={
                <GenericButton
                    type="close"
                    onClick={() => {
                        navigate(-1);
                    }}
                />
            }>
           Generează raport
        </PageTitle>
        <Typography variant="h3" mb={1.6}>Pentru a genera un raport sunt necesare cel putin 10 teste finalizate pentru campurile selectate.</Typography>
        <BoxContent>
            <Box width="100%">
                <SchoolReportForm
                    startDate = {startDate}
                    endDate = {endDate}
                    onDateRangeChange = {onDateRangeChange}
                    onSubmit = {onSubmit}
                    tags={tags}
                    tag={tag}
                    methods={methods}
                />
                <Grid container spacing={1.5} alignItems="center">
                    <FormLabelBox />
                    <FormFieldBox>
                        <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
                    </FormFieldBox>
                </Grid>
            </Box>
        </BoxContent>

    </Box>
};

export default SchoolReportGenerate;