import {IFormCreateTestTraining, IProps} from "../_interfaces";
import {useAuth} from "../../../hooks/useAuth";
import React, {useState} from "react";
import useCreateTestForm from "../../../hooks/useCreateTestForm";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {testTrainingSchema} from "../_schemas";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Loading from "../../ui/Loading";
import CreateTestConfig from "../CreateTestConfig";
import TestPreview from "./TestPreview";
import AddTestButtonLayout from "../AddTestButtonLayout";
import Icon from "../../ui/Icon";
import CustomButton from "../../ui/CustomButton";
import CustomItemsList from "./CustomItemsList";
import moment from "moment/moment";
import {APICreateTestCustom} from "../../../api/pages/createTestCustom";

const CreateTestCustom = ({ testType }: IProps) => {
    const { update } = useAuth();
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        step,
        setStep,
        loading,
        setLoading,
        grades,
        items,
        subjects,
        createTestData,
        selectedStudents,
        tags,
        setClassroomHandler,
        setSelectedStudentsHandler,
        setSelectAllStudentsHandler,
        handleSelectChange,
        handleInputChange,
        setSubjectHandler,
        setGradeHandler,
        confirmPreviousStep,
        handleTestStartDate,
        getCustomItemsHandler,
        setCustomItemsHandler,
        removeCustomItemHandler,
        setSelectedCategoryHandler,
        resetSelectedCategory
    } = useCreateTestForm(testType, "training");

    const methods = useForm<IFormCreateTestTraining>({
        resolver: yupResolver(testTrainingSchema),
    });

    const onSubmit: SubmitHandler<IFormCreateTestTraining> = (data) => {
        let selectedCategories: number[] = [];
        let selectedItems = items.map(({ id }: any) => id);
        let selectedStudents = createTestData.users.filter((item: any) => item.checked === true).map(({ id }: any) => id);
        selectedCategories = items.map(({ subcategory_id }: any) => subcategory_id);

        methods.clearErrors();
        setErrorMessage("");

        if (step === 1) {
            setLoading({ loading: true, name: "step" });
            getCustomItemsHandler();
        } else if (step === 2) {
            setStep(3);
        } else {
            update("loading", { show: true, modal: true });
            APICreateTestCustom({
                grade: createTestData.grade.value,
                classroom_id: createTestData.classroom?.value,
                subcategories: selectedCategories,
                users: selectedStudents,
                test_config: createTestData.test_config.value,
                start_at: moment(createTestData.start_date).locale("ro").format("YYYY-MM-DD HH:mm:ss"),
                expire: createTestData.expire,
                items: selectedItems,
                item_type_id: createTestData.subject.value,
                tag: createTestData.tag.value,
            })
                .then((response) => {
                    if (response.data.success) {
                        setSuccessMessage(
                            'Am pornit serviciul de generare a sesiunilor de testare pentru clasa selectată. Acestea le vor fi transmise elevilor pe e-mail, iar în pagina Teste proprii, secțiunea Teste active, veți regăsi sesiunile generate în ordine descrescătoare.',
                        );
                        update("loading", { show: false });
                    } else {
                        update("loading", { show: false });
                        update("error", { show: true, code: "A002" });
                    }
                })
                .catch((err) => {
                    update("loading", { show: false });
                    if (err.response.status === 400) {
                        setErrorMessage("Unul sau mai multe câmpuri nu sunt valide.");
                        let errors = err.response.data.data;
                        if (errors.classroom) {
                            methods.setError("classroom", { type: "server", message: errors.classroom[0] });
                        }
                        if (errors.users) {
                            methods.setError("users", { type: "server", message: errors.users[0] });
                        }
                        if (errors.test_config) {
                            methods.setError("test_config", { type: "server", message: errors.test_config[0] });
                        }
                        if (errors.start_at) {
                            methods.setError("start_date", { type: "server", message: errors.start_at[0] });
                        }
                        if (errors.expire) {
                            methods.setError("expire", { type: "server", message: errors.expire[0] });
                        }
                        if (errors.subject) {
                            methods.setError("subject", { type: "server", message: errors.subject[0] });
                        }
                        if (errors.grade) {
                            methods.setError("grade", { type: "server", message: errors.grade[0] });
                        }
                        if (errors.categories) {
                            methods.setError("categories", { type: "server", message: errors.categories[0] });
                        }
                        if (errors.difficulty) {
                            methods.setError("difficulty", { type: "server", message: errors.difficulty[0] });
                        }
                        if (errors.items_number) {
                            methods.setError("items_number", { type: "server", message: errors.items_number[0] });
                        }
                        if (errors.individual_test) {
                            methods.setError("individual_test", { type: "server", message: errors.individual_test[0] });
                        }
                        if (errors.tag) {
                            methods.setError("tag", { type: "server", message: errors.tag[0] });
                        }
                        if(errors.credits === 0) {
                            setErrorMessage(errors.message);
                        }
                        update("loading", { show: false });
                    } else {
                        update("error", { show: true, code: "A001" });
                    }
                });
        }

    };

    return (
        <>
            <Box width="100%">
                {successMessage ? (
                    <Typography>{successMessage}</Typography>
                ) : (
                    <>
                        {loading.loading && loading.name === "step" ? (
                            <>
                                <Loading show={true} />
                            </>
                        ) : (
                            <FormProvider {...methods}>
                                <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                                    <Box mb={{ xs: 5, md: 3 }}>
                                        <>
                                            {step === 1 && (
                                                <CreateTestConfig
                                                    type="customTest"
                                                    subjects={subjects}
                                                    grades={grades}
                                                    createTestData={createTestData}
                                                    selectedStudents={selectedStudents}
                                                    setClassroomHandler={setClassroomHandler}
                                                    setSelectedStudentsHandler={setSelectedStudentsHandler}
                                                    setSelectAllStudentsHandler={setSelectAllStudentsHandler}
                                                    handleSelectChange={handleSelectChange}
                                                    handleInputChange={handleInputChange}
                                                    setSubjectHandler={setSubjectHandler}
                                                    setGradeHandler={setGradeHandler}
                                                    handleTestStartDate={handleTestStartDate}
                                                    loading={loading}
                                                    errors={methods.formState.errors}
                                                    tags={tags}
                                                />
                                            )}
                                            {step === 2 && (
                                                <CustomItemsList
                                                    loading={loading}
                                                    setLoading={setLoading}
                                                    createTestData={createTestData}
                                                    setCustomItemsHandler={setCustomItemsHandler}
                                                    removeCustomItemHandler={removeCustomItemHandler}
                                                    setSelectedCategoryHandler={setSelectedCategoryHandler}
                                                    selectedItems={items}
                                                    resetSelectedCategory={resetSelectedCategory}
                                                />
                                            )}
                                            {step === 3 && (
                                                <TestPreview
                                                    items={items}
                                                    removeCustomItemHandler={removeCustomItemHandler}
                                                />
                                            )}
                                        </>
                                    </Box>
                                    {createTestData.grade.value !== "" && createTestData.users.some((el: any) => el.checked === true) && (
                                            <AddTestButtonLayout>
                                                <Box display="flex" justifyContent="space-between">
                                                    {errorMessage && (
                                                        <Box display="flex" alignItems="flex-start" mt={0.5} mr={{xs: 0, md: 1}}>
                                                            <Box color="error.main">
                                                                <Icon icon="circle-exclamation" fixedWidth={true} sx={{ fontSize: "1rem" }} />
                                                            </Box>
                                                            <Box flexGrow={1} mt={0.25}>
                                                                <Typography variant="body2" color="error.main">{errorMessage}</Typography>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                    <CustomButton
                                                        buttonType="textIcon" disabled={step === 1}
                                                        onClick={() => {
                                                            confirmPreviousStep();
                                                        }} sx={{whiteSpace: "nowrap"}}>
                                                        Pasul anterior
                                                    </CustomButton>
                                                    <CustomButton disabled={(step === 2 && items.length === 0) || (step === 3 && items.length === 0)} buttonType="textIcon" type="submit" sx={{whiteSpace: "nowrap"}}>
                                                        {step === 3 ? "Generează test" : "Pasul urmator"}
                                                    </CustomButton>
                                                </Box>
                                            </AddTestButtonLayout>
                                        )}
                                </form>
                            </FormProvider>
                        )}
                    </>
                )}
            </Box>
        </>
    )
}

export default CreateTestCustom;