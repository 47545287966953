import React, { useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import ErrorsList from "components/ui/ErrorsList";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Icon from "components/ui/Icon";
import CustomButton from "../ui/CustomButton";

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  position: "relative",
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "white",
}));

interface Props {
  name: string;
  type?: "field" | "button";
  defaultValue?: string;
  errorMessage?: string;
  label?: string;
  updateFileCb: (file: File | null, fileUrl: string, name: string) => void;
  error?: boolean;
  fileType?: "image" | "xls";
}

const UploadButton = ({ name, type = "field", defaultValue = "", label, errorMessage = "", updateFileCb, error = false, fileType = "image" }: Props) => {
  const methods = useFormContext();
  const [fileError, setFileError] = useState<string>("");
  const fileInputField = useRef<HTMLInputElement>(null);
  const fileTypes = ["xlsx", "xls"].map(function(x) {return "." + x;}).join(",");

  const callUpdateFilesCb = (file: File | null, fileUrl: string, name: string) => {
    updateFileCb(file, fileUrl, name);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if(type === "field") {
      methods.clearErrors("file");
    }

    if (!fileList) return;

    if(fileType === "image" || fileType === "xls") {
        let updatedFile = fileList[0];
        let updatedFileUrl = URL.createObjectURL(updatedFile);
        setFileError("");
        callUpdateFilesCb(updatedFile, updatedFileUrl, e.target.name);
    } else {
            setFileError("Tipul de fișier nu este permis");
        }

  };

  // const handleFileDelete = (e: React.MouseEvent) => {
  //   let updatedFile = null;
  //   let updatedFileUrl = "";
  //   setSelectedFile(updatedFile);
  //   setFileUrl(updatedFileUrl);
  //   setFileError("");
  //   callUpdateFilesCb(updatedFile, updatedFileUrl);
  // };

    return (
    <>
      <Box>
          {type === "field" && (
              <>
                <StyledBox sx={{border: error? "1px solid #9D0000" : "none"}}>
                    <Controller
                        name={name}
                        control={methods.control}
                        defaultValue={defaultValue}
                        render={({ field }) => (
                            <Box sx={{ textAlign: "center" }}>
                                <Icon icon="fat cloud-arrow-up" fixedWidth={true} sx={{ fontSize: "3rem", color: error ? "error.main" : "primary.light"}} />
                                <Typography variant="h5" sx={{color: error ? "error.main" : "secondary.light"}}>
                                    {fileType === "image" ? "Încarcă o imagine" : "Încarcă un fișier"}
                                </Typography>
                                <input
                                    style={{
                                        fontSize: "18px",
                                        display: "block",
                                        width: " 100%",
                                        border: "none",
                                        textTransform: "none",
                                        position: "absolute",
                                        cursor: "pointer",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        opacity: 0,
                                    }}
                                    {...field}
                                    //@ts-ignore
                                    accept={fileType === "image" ? "image/*" : fileTypes}
                                    id="file"
                                    type="file"
                                    onChange={handleFileChange}
                                    ref={fileInputField}
                                />
                            </Box>
                        )}
                    />
                </StyledBox>
                <Typography variant="body2">Tipuri de fișiere permise: {fileType === "image" ? "jpg, jpeg, png." : "xlsx."} Fișierul nu poate depăși 5MB. </Typography>
                <ErrorsList show={fileError !== ""}>{fileError}</ErrorsList>
                <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
              </>
          )}

          {type === "button" && (
              <Box>
                <input
                    name={name}
                    type="file"
                    accept={fileType === "image" ? "image/*" : fileTypes}
                    style={{ display: "none" }}
                    id={name}
                    onChange={handleFileChange}
                />
                <label htmlFor={name}>
                  <CustomButton
                    component="span"
                      buttonType="textIcon"
                      sx={{whiteSpace: "nowrap"}}
                      icon={<Icon icon="fat cloud-arrow-up" fixedWidth={true}/>}>
                    Încarcă imagine
                  </CustomButton>
                </label>
              </Box>
          )}
      </Box>
    </>
  );
};

export default UploadButton;
