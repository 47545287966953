import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import ErrorsList from "components/ui/ErrorsList";
import FormGroup from "@mui/material/FormGroup";
import Divider from "@mui/material/Divider";

interface Option {
  value: string;
  label: string | JSX.Element;
  [key: string]: any;
}
interface Props {
  type?: string;
  name: string;
  label?: string | JSX.Element;
  defaultValue?: boolean | null;
  checked?: boolean;
  selected? : any[];
  selectAll?: boolean;
  errorMessage?: string | undefined;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectAll?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelPlacement?: "top" | "bottom" | "end" | "start" | undefined;
  options?: Option[];
  columns?: boolean;
  error?: boolean;
}

const CheckBoxField = ({
  type = "checkbox",
  name,
  label,
  defaultValue,
  checked = false,
  errorMessage = "",
  className = "",
  selected = [],
  selectAll = false,
  onChange,
  onSelectAll,
  labelPlacement = "start",
  options = [],
  columns = false,
  error = false,
}: Props) => {
  const methods = useFormContext();
  return (
    <Box width="100%">
      {type === "checkbox" && (
          <Box width="100%">
        <Controller
          name={name}
          control={methods.control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={onChange} />}
              label={label}
              labelPlacement={labelPlacement}
            />
          )}
        />
          </Box>
      )}
      {type === "checkbox-group" && (
        <Controller
          name={name}
          control={methods.control}
          render={({ field }) => (
            <FormGroup>
              {selectAll &&
                  <>
                    <Box width="100%" mb={0.5}>
                      <FormControlLabel
                          sx={{color: "text.info"}}
                          label={selected.length === options.length ? "Deselectează toți elevii" : "Selectează toți elevii"}
                          control={
                            <Checkbox
                                checked={selected.length === options.length}
                                name="all"
                                indeterminate={(selected.length !== options.length) && (selected.length !== 0)}
                                onChange={onSelectAll}
                                sx={{
                                  '&.Mui-checked': {
                                    color: "text.info",
                                  },
                                  '&.MuiCheckbox-indeterminate': {
                                    color: "text.info",
                                  }
                                }}
                            />
                          }
                      />
                    </Box>
                    <Divider sx={{ borderColor: "primary.light", marginBottom: 1.5, marginTop: 1 }} />
                  </>
              }
              <Box width="100%" sx={{ columnCount: { xs: 1, md: columns ? 2 : 1, lg: columns ? 3 : 1 } }}>
                {options.map((option) => (
                  <Box key={option.value} width="100%" mb={0.5}>
                    <FormControlLabel
                      disabled={option.disabled}
                      control={<Checkbox checked={option.checked} name={option.value.toString()} onChange={onChange} sx={{
                        '&.Mui-checked': {
                          color: "text.primary",
                        },
                        '&.Mui-disabled': {
                          color: "text.disabled",
                        },
                      }} />}
                      label={option.label}
                      labelPlacement={labelPlacement}
                    />
                  </Box>
                ))}
              </Box>
            </FormGroup>
          )}
        />
      )}

      <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
    </Box>
  );
};

export default CheckBoxField;
