import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { MathJaxContext } from "better-react-mathjax";
import LayoutProtected from "components/layout/Protected";
import LayoutPublic from "components/layout/Public";
import LayoutProtectedNoSidebar from "components/layout/ProtectedNoSidebar";
import themeLight from "themes/light";
import themeDark from "themes/dark";
import LoginPage from "pages/login/Login";
import TeachersPage from "pages/users/Teachers";
import TeacherPage from "pages/users/Teacher";
import StudentsPage from "pages/users/Students";
import StudentPage from "pages/users/Student";
// import ChildrenPage from "pages/Children";
import ClassroomsPage from "pages/users/Classrooms";
import ClassroomPage from "pages/users/Classroom";
import TestsSchoolPage from "pages/tests/TestsSchool";
import TestSchoolPage from "pages/tests/TestCreate";
import TestsLiteracyPage from "pages/tests/TestsLiteracy";
import TestsDigitalLiteracyPage from "pages/tests/TestsDigitalLiteracy";
import StudentAnswersReportPage from "pages/reports/StudentAnswersReport";
import StudentAnswersReportLiteracyPage from "pages/reports/StudentAnswersReportLiteracy";
import TestFinishedPage from "pages/tests/TestFinished";
import TestPage from "pages/tests/Test";
import TestClientPage from "pages/tests/TestClient";
import ProfilePage from "pages/settings/Profile";
import AddressPage from "pages/settings/Address";
import AddressesPage from "pages/settings/Addresses";
// import CardPage from "pages/Card";
// import CardsPage from "pages/Cards";
// import SubscriptionsPage from "pages/Subscriptions";
// import CheckoutPage from "pages/Checkout";
import LogoutPage from "pages/login/Logout";
import StatisticsPage from "pages/Statistics";
import FaqPage from "pages/assistance/Faq";
import { useAuth } from "hooks/useAuth";
import "App.css";
import 'simplebar-react/dist/simplebar.min.css';
import "utils/icons";
import Loading from "components/ui/Loading";
import ErrorModal from "components/ui/ErrorModal";
import ConfirmModal from "components/ui/ConfirmModal";
import NotFoundPage from "pages/NotFound";
// import PusherFunction from "components/notifications/PusherFunction";
import MessagesPage from "pages/assistance/Messages";
import Feedback from "pages/assistance/Feedback";
import RegisterPage from "pages/login/Register";
import EmailVerify from "pages/login/EmailVerify";
import LayoutUnverified from "components/layout/Unverified";
import ForgotPassword from "pages/login/ForgotPassword";
import ForgotPasswordTeachers from "pages/login/ForgotPasswordTeachers";
import ContactPage from "pages/assistance/Contact";
// import ChildPage from "pages/ChildPage";
import ReportPage from "pages/reports/Report";
import ReportLiteracyPage from "pages/reports/ReportLiteracy";
import ReportDigitalLiteracyPage from "pages/reports/ReportDigitalLiteracy";
import PdfReportPage from "pages/reports/PdfReport";
import PdfReportLiteracyPage from "pages/reports/PdfReportLiteracy";
import PdfReportDigitalLiteracyPage from "pages/reports/PdfReportDigitalLiteracy";
import TestsTrainingPage from "pages/tests/TestsTraining";
import TestsBacPage from "pages/tests/TestsBac";
import TestsEvaluationPage from "pages/tests/TestsEvaluation";
import LoginTeachersPage from "./pages/login/LoginTeachers";
import LoginTestsPage from "pages/login/LoginTests";
import UsersImportPage from "pages/settings/UsersImport";
import ProfilesPage from "pages/settings/Profiles";
import LayoutProtectedSwap from "components/layout/ProtectedSwap";
import PublicFullScreen from "./components/layout/PublicFullScreen";
import TestFinishedClientPage from "pages/tests/TestFinishedClient";
import RegisterSchoolTeachersPage from "./pages/login/RegisterSchoolTeachers";
import SchoolReportsPage from "./pages/reports/SchoolReports";
import SchoolReportGeneratePage from "./pages/reports/SchoolReportGenerate";
import SchoolReportPage from "./pages/reports/SchoolReport";
import TestTagsPage from "./pages/settings/TestTags";
import TestTagPage from "./pages/settings/TestTag";
import TutorialsPage from "./pages/assistance/Tutorials";
import TestsCustomPage from "./pages/tests/TestsCustom";
import CustomTestItems from "./pages/tests/CustomTestItems";
import CustomTestItem from "pages/tests/CustomTestItem";
import StudentAnswersReportCustomTest from "./pages/reports/StudentsAnswersReportCustomTest";
import MediaModal from "./components/ui/textEditor/MediaModal";


const config = {
  loader: { load: ["[tex]/html"] },
  tex: {
    packages: { "[+]": ["html"] },
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"]
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"]
    ]
  }
};

function App() {
  const { user, tenant, loading, error, confirm, media } = useAuth();

  const themeMode = React.useMemo(() => {
    return user?.theme === "dark" ? themeDark : themeLight;
  }, [user]);

  return user === null ||
    !user.hasOwnProperty("theme") ||
    user.theme === "" ||
    tenant === null ||
    error === null ? null : (
    <MathJaxContext version={3} config={config}>
      <ThemeProvider theme={themeMode}>
        <CssBaseline enableColorScheme />
        <div className="App">
          <Routes>
            <Route element={<LayoutPublic />}>
              <Route path="/" element={<LoginPage />} />
              <Route path="/login/profesori" element={<LoginTeachersPage />} />
              <Route path="/login/teste" element={<LoginTestsPage />} />
              <Route path="/inregistrare" element={<RegisterSchoolTeachersPage />} />
              <Route path="/inregistrare/profesori" element={<RegisterPage />} />
              <Route path="/resetare-parola" element={<ForgotPassword />} />
              <Route path="/login/profesori/resetare-parola" element={<ForgotPasswordTeachers />} />
            </Route>

            <Route element={<PublicFullScreen />}>
              <Route path="/test">
                <Route path=":id" element={<TestClientPage />} />
              </Route>
              <Route path="/logout" element={<LogoutPage />} />
            </Route>

            <Route element={<LayoutUnverified />}>
              <Route path="/verifica-email" element={<EmailVerify />} />
              <Route path="/login/profesori/verifica-email" element={<EmailVerify />} />
            </Route>

            <Route element={<LayoutProtectedSwap allowedRoles={["principal", "teacher"]} />}>
              <Route path="/profile" element={<ProfilesPage />} />
            </Route>

            <Route element={<LayoutProtected allowedRoles={["teacher"]}/>}>
              <Route path="/teste/adauga-test-scolar" element={<TestSchoolPage />} />
              <Route path="/teste-bac/adauga-test-bac" element={<TestSchoolPage />} />
              <Route path="/teste-evaluare/adauga-test-evaluare" element={<TestSchoolPage />} />
              <Route path="/teste-antrenament/adauga-test-antrenament" element={<TestSchoolPage />} />
              <Route path="/teste-literatie/adauga-test-literatie" element={<TestSchoolPage />} />
              <Route path="/teste-literatie-digitala/adauga-test-literatie-digitala" element={<TestSchoolPage />} />
              <Route path="/teste-proprii/adauga-test" element={<TestSchoolPage />} />
            </Route>

            <Route element={<LayoutProtected allowedRoles={["teacher", "principal", "student"]}/>}>
              <Route path="/statistici" element={<StatisticsPage />} />
              <Route path="/intrebari-frecvente" element={<FaqPage />} />
              <Route path="/tutoriale" element={<TutorialsPage />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/logout" element={<LogoutPage />} />
              <Route path="/mesaje" element={<MessagesPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/profil">
                <Route path="" element={<ProfilePage />} />
                <Route path=":action" element={<ProfilePage />} />
              </Route>
              <Route path="/teste">
                <Route path="" element={<TestsSchoolPage />} />
                <Route path="teste-finalizate" element={<TestsSchoolPage />} />
              </Route>
              <Route path="/teste-bac">
                <Route path="" element={<TestsBacPage />} />
                <Route path="teste-finalizate" element={<TestsBacPage />} />
              </Route>
              <Route path="/teste-evaluare">
                <Route path="" element={<TestsEvaluationPage />} />
                <Route path="teste-finalizate" element={<TestsEvaluationPage />} />
              </Route>
              <Route path="teste-antrenament">
                <Route path="" element={<TestsTrainingPage />} />
                <Route path="teste-finalizate" element={<TestsTrainingPage />} />
              </Route>
              <Route path="teste-literatie">
                <Route path="" element={<TestsLiteracyPage />} />
                <Route path="teste-finalizate" element={<TestsLiteracyPage />} />
              </Route>
              <Route path="teste-literatie-digitala">
                <Route path="" element={<TestsDigitalLiteracyPage />} />
                <Route path="teste-finalizate" element={<TestsDigitalLiteracyPage />} />
              </Route>
              <Route path="teste-proprii">
                <Route path="" element={<TestsCustomPage />} />
                <Route path="teste-finalizate" element={<TestsCustomPage />} />
              </Route>
            </Route>

            <Route element={<LayoutProtected allowedRoles={["teacher", "principal"]}/>}>
              <Route path="/elevi">
                <Route path="" element={<StudentsPage />} />
                <Route path="page/1" element={<Navigate to="/elevi" replace />} />
                <Route path="page/:pageNumber" element={<StudentsPage />} />
                <Route path="arhiva" element={<StudentsPage />} />
                <Route path=":id/:action" element={<StudentPage />} />
                <Route path=":id" element={<StudentPage />} />
                <Route path="adauga" element={<StudentPage />} />
              </Route>
              {/* <Route path="/copii">
                <Route path="" element={<ChildrenPage />} />
                <Route path=":id/:action" element={<ChildPage />} />
                <Route path=":id" element={<ChildPage />} />
                <Route path="adauga" element={<ChildPage />} />
              </Route> */}
              <Route path="/clase">
                <Route path="" element={<ClassroomsPage />} />
                <Route path=":id/:action" element={<ClassroomPage />} />
                <Route path=":id" element={<ClassroomPage />} />
                <Route path="adauga" element={<ClassroomPage />} />
              </Route>
              <Route path="itemi-proprii">
                <Route path="" element={<CustomTestItems />} />
                <Route path=":id/:action" element={<CustomTestItem />} />
                <Route path=":id" element={<CustomTestItem />} />
                <Route path="adauga" element={<CustomTestItem />} />
              </Route>
            </Route>

            <Route element={<LayoutProtected allowedRoles={tenant.type === "teacher" ? ["principal", "teacher"] : ["principal"]} />}>
              <Route path="/adrese">
                <Route path="" element={<AddressesPage />} />
                <Route path=":id/:action" element={<AddressPage />} />
                <Route path="adauga" element={<AddressPage />} />
              </Route>
              <Route path="/importa" element={<UsersImportPage />} />
              {/*<Route path="/carduri">*/}
              {/*  <Route path="" element={<CardsPage />} />*/}
              {/*  <Route path="adauga" element={<CardPage />} />*/}
              {/*</Route>*/}
              {/*<Route path="/abonamente-extraoptiuni">*/}
              {/*  <Route path="" element={<SubscriptionsPage />} />*/}
              {/*  <Route path="checkout" element={<CheckoutPage />} />*/}
              {/*</Route>*/}

              {/* <Route path="/itemi-adaugati" element={<ItemsAddedPage />} /> */}
            </Route>

            <Route element={<LayoutProtected allowedRoles={["principal"]} />}>
              <Route path="/profesori">
                <Route path="" element={<TeachersPage />} />
                <Route path=":id/:action" element={<TeacherPage />} />
                <Route path=":id" element={<TeacherPage />} />
                <Route path="adauga" element={<TeacherPage />} />
              </Route>
              <Route path="/taguri">
                <Route path="" element={<TestTagsPage />} />
                <Route path="taguri-adaugate" element={<TestTagsPage />} />
                <Route path="adauga" element={<TestTagPage />} />
              </Route>
              <Route path="/rapoarte">
                <Route path="" element={<SchoolReportsPage />} />
                <Route path="genereaza" element={<SchoolReportGeneratePage />} />
              </Route>
            </Route>

            <Route element={<LayoutProtectedNoSidebar allowedRoles={["principal"]} />}>
              <Route path="/rapoarte">
                <Route path=":id" element={<SchoolReportPage />} />
              </Route>
            </Route>

            <Route element={<LayoutProtected fullScreen={true} allowedRoles={["student"]} />}>
              <Route path="/teste">
                <Route path=":id" element={<TestPage />} />
              </Route>
            </Route>

            <Route element={<LayoutProtected allowedRoles={["student"]} />}>
              <Route path="/teste/test-finalizat" element={<TestFinishedPage />} />
              <Route path="/teste-bac/test-finalizat" element={<TestFinishedPage />} />
              <Route path="/teste-evaluare/test-finalizat" element={<TestFinishedPage />} />
              <Route path="/teste-antrenament/test-finalizat" element={<TestFinishedPage />} />
              <Route path="teste-literatie/test-finalizat" element={<TestFinishedPage />} />
              <Route path="/teste-literatie-digitala/test-finalizat" element={<TestFinishedPage />} />
              <Route path="/teste-proprii/test-finalizat" element={<TestFinishedPage />} />
            </Route>

            <Route element={<LayoutProtected fullScreen={true} allowedRoles={["teacher", "principal", "student"]}/>}>
              <Route path="teste/raportpdf/">
                <Route path=":id" element={<PdfReportPage />} />
              </Route>
              <Route path="teste-literatie-digitala/raportpdf/">
                <Route path=":id" element={<PdfReportDigitalLiteracyPage />} />
              </Route>
              <Route path="teste-literatie/raportpdf/">
                <Route path=":id" element={<PdfReportLiteracyPage />} />
              </Route>
            </Route>

            <Route element={<LayoutProtectedNoSidebar allowedRoles={["teacher", "principal", "student"]}/>}>
              <Route path="teste/raport/:id" element={<ReportPage />} />
              <Route path="teste/raspunsuri/:id" element={<StudentAnswersReportPage />} />
              <Route path="teste-bac/raport/:id" element={<ReportPage />} />
              <Route path="teste-bac/raspunsuri/:id" element={<StudentAnswersReportPage />} />
              <Route path="teste-evaluare/raport/:id" element={<ReportPage />} />
              <Route path="teste-evaluare/raspunsuri/:id" element={<StudentAnswersReportPage />} />
              <Route path="teste-antrenament/raspunsuri/:id" element={<StudentAnswersReportPage />} />
              <Route path="teste-proprii/raspunsuri/:id" element={<StudentAnswersReportCustomTest />} />
              <Route path="teste-literatie/raspunsuri/:id" element={<StudentAnswersReportLiteracyPage />} />
              <Route path="teste-literatie/raport/:id" element={<ReportLiteracyPage />} />
              <Route path="teste-literatie-digitala/raspunsuri/:id" element={<StudentAnswersReportPage />} />
              <Route path="teste-literatie-digitala/raport/:id" element={<ReportDigitalLiteracyPage />} />
            </Route>

            <Route element={<LayoutProtectedNoSidebar allowedRoles={["teacher", "principal"]}/>}>
              <Route path="teste/previzualizare/:id" element={<StudentAnswersReportPage />} />
              <Route path="teste-bac/previzualizare/:id" element={<StudentAnswersReportPage />} />
              <Route path="teste-evaluare/previzualizare/:id" element={<StudentAnswersReportPage />} />
              <Route path="teste-antrenament/previzualizare/:id" element={<StudentAnswersReportPage />} />
              <Route path="teste-literatie/previzualizare/:id" element={<StudentAnswersReportLiteracyPage />} />
              <Route path="teste-literatie-digitala/previzualizare/:id" element={<StudentAnswersReportPage />} />
              <Route path="teste-proprii/previzualizare/:id" element={<StudentAnswersReportCustomTest />} />
            </Route>

            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/not-found" element={<NotFoundPage />} />
            <Route path="/test/test-finalizat" element={<TestFinishedClientPage />} />

          </Routes>
          <Loading show={loading.show} modal={loading.modal} />
          <ErrorModal error={error} />
          <ConfirmModal confirm={confirm} />
          <MediaModal media={media} />
          {/*<PusherFunction />*/}
        </div>
      </ThemeProvider>
    </MathJaxContext>
  );
}

export default App;
