import React, {forwardRef, useEffect, useRef, useState} from 'react';
import Quill from "quill";
import 'quill/dist/quill.snow.css';
import './CustomTextEditor.css';
import ErrorsList from "../ErrorsList";
import {useAuth} from "../../../hooks/useAuth";
import {UseFormClearErrors} from "react-hook-form";
import {IFormCustomItemsEdit} from "../../../pages/tests/CustomTestItem";

interface ICustomTestEditor {
    defaultValue: string;
    name: string;
    errorMessage: string | undefined;
    imageHandler: (name: string, file: string) => void;
    passText: (trimContent: string) => void;
    clearErrors: UseFormClearErrors<IFormCustomItemsEdit>;
}

// Editor is an uncontrolled React component
const CustomTextEditor = forwardRef(
    ({defaultValue, name, errorMessage = "", imageHandler, passText, clearErrors } : ICustomTestEditor, ref) => {
        const {update} = useAuth();
        const containerRef = useRef(null);
        const defaultValueRef = useRef(defaultValue);
        const [selectedFile, setSelectedFile] = useState<any>(null);

        useEffect(() => {
            const container = containerRef.current;
            // @ts-ignore
            const editorContainer = container.appendChild(
                // @ts-ignore
                container.ownerDocument.createElement('div'),
            );
            const quill = new Quill(editorContainer, {
                theme: 'snow',
                formats: [
                    'bold',
                    'header',
                    'italic',
                    'underline',
                    'list',
                    'indent',
                    'script',
                    "align",
                    'image'
                ],
                modules: {
                    toolbar: [
                        [{ header: [1, 2, false] }],
                        ['bold', 'italic', 'underline'],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'script': 'sub'}, { 'script': 'super' }, 'image'],
                        ['align', { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
                    ],
                    clipboard: {
                        onCapturePaste: false
                    }
                }
            });

            if (defaultValueRef.current) {
                quill.root.innerHTML = defaultValue;
            }

            // @ts-ignore
            ref.current = quill;

            const toolbar = quill.getModule('toolbar');
            // @ts-ignore
            toolbar.addHandler('image', () => addImageHandler(name));

            quill.on(Quill.events.TEXT_CHANGE, () => {
                let content = quill.root.innerHTML;
                let trimContent = content.replace("<p><br></p>", "");
                passText(trimContent);
                // @ts-ignore
                clearErrors(name);
            });

            return () => {
                // @ts-ignore
                ref.current = null;
                // @ts-ignore
                container.innerHTML = '';
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ref]);

        const selectImageHandler = (file: any) => {
            setSelectedFile(file);

            update("media", {
                show: true,
                closable: true,
                data: [file],
                size: "big",
                title: "Adaugă imagine",
                returnFunction: (callback: Function) => {
                    imageHandler(name, file.file);
                },
                onClickFunction: selectImageHandler,
                buttonYes: "Adaugă",
                buttonNo: "Închide",
            });
        }

        const addImageHandler = (name: string) => {
            update("media", {
                show: true,
                closable: true,
                data: [selectedFile],
                size: "big",
                title: "Adaugă imagine",
                onClickFunction: selectImageHandler,
                buttonYes: "Adaugă",
                buttonNo: "Închide",
            });
        }

        return (
            <>
                <div className='quill' style={{border: errorMessage !== "" ? "1px solid #9D0000" : "none"}}>
                    <div ref={containerRef}></div>
                </div>
                <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
            </>
        );
    },
);

CustomTextEditor.displayName = 'Editor';

export default CustomTextEditor;