import Typography from "@mui/material/Typography";
import FormField from "components/form/FormField";
import BoxContent from "components/ui/BoxContent";

const CreateTestDuration = ({ errors, itemsNumber, handleSelectChange }: any) => {
    return (
        <>
            <Typography variant="h3" mb={3} mt={3}>
                Alege numărul de itemi
            </Typography>
            <BoxContent>
                <FormField
                    name="test_duration"
                    type="controlled-select"
                    label="Alege numărul de itemi"
                    value={itemsNumber}
                    defaultValue={"default"}
                    onChange={handleSelectChange}
                    options={[
                        {
                            label: "Toți itemii",
                            value: 0,
                        },
                        {
                            label: "15 (45 minute)",
                            value: 15,
                        },
                        {
                            label: "20 (60 minute)",
                            value: 20,
                        },
                        {
                            label: "25 (75 minute)",
                            value: 25,
                        },
                        {
                            label: "30 (90 minute)",
                            value: 30,
                        },
                        {
                            label: "35 (105 minute)",
                            value: 35,
                        },
                        {
                            label: "40 (120 minute)",
                            value: 40,
                        }
                    ]}
                    errorMessage={errors.test_duration?.message}
                />
            </BoxContent>
        </>
    );
};

export default CreateTestDuration;
