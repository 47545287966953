import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { MathJax } from "better-react-mathjax";
import BoxContent from "components/ui/BoxContent";
import Icon from "components/ui/Icon";
import PageTitle from "components/ui/PageTitle";
import { useLocation, useParams } from "react-router-dom";
import {APIPostFinishCustomTest} from "api/pages/test";
import { useAuth } from "hooks/useAuth";
import Loading from "components/ui/Loading";
import ScrollBar from "../../components/ui/ScrollBar";

const StudentAnswersReportCustomTest = () => {
    const { id = "" } = useParams();
    const location = useLocation();
    const { update } = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [studentAnswers, setStudentAnswers] = useState<any>();
    const [locationUrl, setLocationUrl] = useState<string>("");

    useEffect(() => {
        if (id !== "") {
            setLoading(true);
            let locationArray = location.pathname.split("/");
            setLocationUrl(locationArray[locationArray.length - 2]);
            APIPostFinishCustomTest({test: id})
                .then((response) => {
                    if (response.data.success) {
                        // console.log("PostFinishTest OK", response);
                        setStudentAnswers(response.data.data);
                        setLoading(false);
                    } else {
                        update("error", {show: true, code: "A002"});
                    }
                })
                .catch((err) => {
                    // console.log("PostFinishTest ERR", err);
                    update("error", {show: true, code: "A001"});
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <Box component="main">
            <PageTitle>
                {locationUrl === "previzualizare" ? "Previzualizare sesiune testare:" : "Sesiune testare:"} #{id}
            </PageTitle>
            {loading ? (
                <Loading show={true} />
            ) : (
                <>
                    {locationUrl === "raspunsuri" ? (
                        <>
                            <BoxContent>
                                <ul>
                                    <li>testul a fost alcătuit din {studentAnswers.total_items} itemi</li>
                                    <li>ați omis {studentAnswers.no_answers} itemi</li>
                                    <li>ați răspuns corect la {studentAnswers.right_answers} itemi</li>
                                    <li>ați răspuns greșit la {studentAnswers.wrong_answers} itemi</li>
                                </ul>
                            </BoxContent>
                        </>
                    ) : (
                        <BoxContent>
                            <ul>
                                <li>testul este alcătuit din {studentAnswers.total_items} itemi</li>
                            </ul>
                        </BoxContent>
                    )}

                    {studentAnswers.items.map((item: any, index: any) => (
                        <Box width="100%" key={index} sx={{marginTop: {xs: 2, md: 3}, marginBottom: {xs: 2, md: 3}}}>
                            <BoxContent>
                                <Box width="100%" alignItems="center" sx={{display: {xs: "block", md: "flex"}}}>
                                    {locationUrl === "raspunsuri" && (
                                        <Box sx={{marginRight: {xs: 0, md: 3}, textAlign: {xs: "right", md: "center"}, fontSize: {xs: "2rem" ,md: "2.5rem"}}}>
                                            {item.is_not_answered && (
                                                <Icon
                                                    icon="fas circle-xmark"
                                                    fixedWidth={true}
                                                    sx={{ color: "text.disabled" }}
                                                />
                                            )}
                                            {item.is_wrong_answer && (
                                                <Icon
                                                    icon="fas circle-xmark"
                                                    fixedWidth={true}
                                                    sx={{ color: "error.main" }}
                                                />
                                            )}
                                            {item.is_right_answer && (
                                                <Icon
                                                    icon="fas circle-check"
                                                    fixedWidth={true}
                                                    sx={{ color: "success.main" }}
                                                />
                                            )}
                                        </Box>
                                    )}

                                    <MathJax style={{ width: "100%"}} inline >
                                        {item.scenery && (
                                            <>
                                                <Typography variant="h4">SCENARIU</Typography>
                                                <Typography component="div" width="100%" variant="body1" mb={2} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}>
                                                    <Box dangerouslySetInnerHTML={{ __html: item.scenery }}></Box>
                                                </Typography>
                                            </>
                                        )}
                                        {item.enunciation !== "" && (

                                            <Box mb={2}>
                                                <Typography variant="h4">ENUNȚ</Typography>
                                                <ScrollBar forceVisible="x" autoHide={false} style={{maxHeight: "100%", maxWidth: "100%", overflowY: "hidden"}} >
                                                    <Box component="div" width="100%" mb={1.5} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}>
                                                        <Box dangerouslySetInnerHTML={{ __html: item.enunciation }}></Box>
                                                    </Box>
                                                </ScrollBar>
                                            </Box>
                                        )}


                                        <Box sx={{ flexGrow: 1 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={item.illustration && item.illustration.svg ? 6 : 12} lg={item.illustration && item.illustration.svg ? 8 : 12}>
                                                    {item.question !== "" && (
                                                        <Typography variant="h4">CERINȚĂ</Typography>
                                                    )}
                                                    <ScrollBar forceVisible="x" autoHide={false} style={{overflowY: "hidden"}}>
                                                        {item.question !== "" && (
                                                            <Typography component="div" variant="body1" width="100%" mb={2} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}>
                                                                <Box dangerouslySetInnerHTML={{ __html: item.question }}></Box>
                                                            </Typography>
                                                        )}

                                                        <Box width="fit-content" minWidth="100%">

                                                            <Box
                                                                mb={2}
                                                                sx={{
                                                                    padding: "12px",
                                                                    border: "1px solid",
                                                                    borderColor:
                                                                        item.user_answer === "a" && item.user_answer === item.right_answer && locationUrl === "raspunsuri"
                                                                            ? "#00BA3B"
                                                                            : item.right_answer === "a" && locationUrl === "raspunsuri"
                                                                                ? "#00BA3B"
                                                                                : item.user_answer === "a" && locationUrl === "raspunsuri"
                                                                                    ? "error.main" : "#fff",
                                                                }}
                                                                width="100%"
                                                            >
                                                                <Typography component="div" dangerouslySetInnerHTML={{ __html: item.answer_1 }} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}/>
                                                            </Box>

                                                            <Box
                                                                mb={2}
                                                                sx={{
                                                                    padding: "12px",
                                                                    border:
                                                                        item.user_answer === "b" && item.user_answer === item.right_answer && locationUrl === "raspunsuri"
                                                                            ? "1px solid #00BA3B"
                                                                            : item.right_answer === "b" && locationUrl === "raspunsuri"
                                                                                ? "1px solid #00BA3B"
                                                                                : item.user_answer === "b" && locationUrl === "raspunsuri"
                                                                                    ? "1px solid #9D0000" : "1px solid #fff",
                                                                }}
                                                                width="100%"
                                                            >
                                                                <Typography component="div" dangerouslySetInnerHTML={{ __html: item.answer_2 }} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}/>
                                                            </Box>

                                                            <Box
                                                                mb={2}
                                                                sx={{
                                                                    padding: "12px",
                                                                    border:
                                                                        item.user_answer === "c" && item.user_answer === item.right_answer && locationUrl === "raspunsuri"
                                                                            ? "1px solid #00BA3B"
                                                                            : item.right_answer === "c" && locationUrl === "raspunsuri"
                                                                                ? "1px solid #00BA3B"
                                                                                : item.user_answer === "c" && locationUrl === "raspunsuri"
                                                                                    ? "1px solid #9D0000" : "1px solid #fff",
                                                                }}
                                                                width="100%"
                                                            >
                                                                <Typography component="div" dangerouslySetInnerHTML={{ __html: item.answer_3 }} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}/>
                                                            </Box>


                                                            <Box
                                                                mb={2}
                                                                sx={{
                                                                    padding: "12px",
                                                                    border:
                                                                        item.user_answer === "d" && item.user_answer === item.right_answer && locationUrl === "raspunsuri"
                                                                            ? "1px solid #00BA3B"
                                                                            : item.right_answer === "d" && locationUrl === "raspunsuri"
                                                                                ? "1px solid #00BA3B"
                                                                                : item.user_answer === "d" && locationUrl === "raspunsuri"
                                                                                    ? "1px solid #9D0000" : "1px solid #fff",
                                                                }}
                                                                width="100%"
                                                            >
                                                                <Typography component="div" dangerouslySetInnerHTML={{ __html: item.answer_4 }} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}/>
                                                            </Box>

                                                        </Box>
                                                    </ScrollBar>
                                                </Grid>
                                                {item.illustration && item.illustration.svg && <Grid item xs={12} md={6} lg={4}>
                                                    <Box sx={{backgroundColor: "rgb(230, 230, 230)", borderRadius: "30px", position: "relative", margin: "0 0 30px 0", maxWidth: "400px"}}>
                                                        <img src={item.illustration.svg} alt="" style={{verticalAlign: "bottom", maxWidth: "400px", borderRadius: "30px", margin: "0 auto"}}/>
                                                        <Box sx={{position: "absolute", top:"-1px", left: "25%", borderRadius: "0 0 8px 8px", backgroundColor: "#fff", padding: "9px 20px 9px", fontSize: "12px", lineHeight: "10px", color: "#ADADAD", whiteSpace: "nowrap", textTransform: "uppercase"}}>Ilustrație ajutătoare</Box>
                                                    </Box>
                                                </Grid>
                                                }
                                            </Grid>
                                        </Box>

                                    </MathJax>
                                </Box>
                            </BoxContent>
                        </Box>
                    ))}
                </>
            )}
        </Box>
    );
};

export default StudentAnswersReportCustomTest;
