import PageTitle from "../../components/ui/PageTitle";
import Box from "@mui/material/Box";
import React from "react";
import {useAuth} from "../../hooks/useAuth";
import BoxContent from "../../components/ui/BoxContent";

const Tutorials = () => {
    const {user} = useAuth();

    return (
        <Box component="main">
            <PageTitle>Tutoriale</PageTitle>
            <BoxContent>
                <Box sx={{position: "relative", width: "100%", overflow: "hidden", paddingTop: "56.25%"}}>
                    {user.position === "student" && <>
                        <iframe style={{position: "absolute", top: 0, left: 0, bottom: 0, right: 0, width: "100%", height: "100%", border: "none"}} src="https://www.youtube.com/embed/5VKloia8bnw?si=PUFFdZVGYwT5kZ24" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </>}
                    {user.position === "principal" && <>
                        <iframe style={{position: "absolute", top: 0, left: 0, bottom: 0, right: 0, width: "100%", height: "100%", border: "none"}} src="https://www.youtube.com/embed/xsF8tU3k0vw?si=iTvmg0g_SPmih71k" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </>}
                    {user.position === "teacher" && <>
                        <iframe style={{position: "absolute", top: 0, left: 0, bottom: 0, right: 0, width: "100%", height: "100%", border: "none"}} src="https://www.youtube.com/embed/a6bs46fQnLQ?si=2-oAeUDCnafg80RB" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </>}
                </Box>
            </BoxContent>
        </Box>
    )

}

export default Tutorials;