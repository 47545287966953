import axiosClient from "api/apiClient";

interface IOptions {
    id?: string;
    sort?: string;
    search?: string;
}

export const APIGetCustomItem = (options: IOptions) => {
    let sort = "";
    let search = "";
    if (options.hasOwnProperty("sort")) {
        sort = "&sort=" + options.sort;
    }
    if (options.hasOwnProperty("search")) {
        search = "&filter[search]=" + options.sort;
    }
    return axiosClient.get(`/personalItems/${options.id}?${search}${sort}`);
};

export const APIDeleteCustomItem = (options: IOptions) => {
    return axiosClient.delete(`/personalItems/${options.id}`);
};

export const APIUpdateCustomItem = (id: string, data: object) => {
    return axiosClient.put(`/personalItems/${id}`, data);
};

export const APIPostCustomItem = (data: object) => {
    return axiosClient.post(`/personalItems`, data);
};
