import Box from "@mui/material/Box";
import FormField from "components/form/FormField";
import Grid from "@mui/material/Grid";
import Icon from "../ui/Icon";
import CustomButton from "../ui/CustomButton";
// import LinkStyled from "../ui/LinkStyled";
// import useCreateTestForm from "../../hooks/useCreateTestForm";

export interface IFormInputs {
    keywords: string;
    searchType: string;
    subject: string;
}

interface ISearchForm {
    onSubmit: (data: any) => void;
    query: string;
    onChange: (e: any) => void;
    onClickQuerry: () => void;
    onClickSubcategory: () => void;
    createTestData: any;
    setSelectedCategoryHandler: (e: any) => void;
}

const CustomItemsSearchForm = (
    { onSubmit,
        query,
        onChange,
        onClickQuerry,
        onClickSubcategory,
        createTestData,
        setSelectedCategoryHandler
    }: ISearchForm) => {

    return (
        <Grid container spacing={{xs:1, md: 0.5}} direction="row" justifyContent={{xs: "flex-start", md: "flex-end"}} alignItems="stretch" mt={0} mb={{xs: 1, sm: 1}}>
            <Grid item xs={12} md={3} lg={3}  sx={{ textAlign: { xs: "left", sm: "right" } }}>
                    <Box sx={(theme) => ({borderRadius: theme.spacing(0.6), backgroundColor: "secondary.contrastText", display: "flex", alignItems: "center"})}>
                        {/*<>{console.log(query)}</>*/}
                        <FormField
                            name="keywords"
                            type="text-icon-controlled"
                            label="Caută..."
                            autoComplete = "off"
                            className="dark"
                            value={query}
                            onChange={onChange}
                            onClick={() => onClickQuerry()}
                        />
                    </Box>
            </Grid>
            <Grid item xs={12} md={3} lg={3}  sx={{ textAlign: { xs: "left", sm: "right" } }}>
                <Box sx={(theme) => ({borderRadius: theme.spacing(0.6), backgroundColor: "secondary.contrastText", display: "flex", alignItems: "center"})}>
                    <FormField
                        disabled={!createTestData.grade.value}
                        name="category"
                        type="controlled-select-small"
                        label="Alege subcapitolul"
                        value={createTestData.category.value}
                        onChange={setSelectedCategoryHandler}
                        onClick={() => onClickSubcategory()}
                        options={createTestData.categories?.map((category: any) => {
                            if(Array.isArray(category.subcategories)) {
                                return category.subcategories?.map((subcategory: any) => {
                                    // console.log(category);
                                    return {
                                        label: subcategory.name,
                                        value: subcategory.id.toString(),
                                        ...subcategory,
                                    };
                                })
                            } else {
                                return {
                                    category,
                                };
                            }
                        })
                            .flat()}
                    />
                </Box>
            </Grid>
            <Grid item xs="auto" md="auto" lg="auto"  sx={{ textAlign: "left" }}>
                <CustomButton
                    bgcolor="dark"
                    onClick={onSubmit}
                    buttonType="textIcon"
                    icon={<Icon icon="far magnifying-glass" fixedWidth={true}/>}
                    // appearance='big'
                >
                    Caută
                </CustomButton>
            </Grid>
        </Grid>
    );
};

export default CustomItemsSearchForm;
