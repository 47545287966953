import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import BoxContent from "components/ui/BoxContent";
import GenericButton from "components/ui/GenericButton";
import PageTitle from "components/ui/PageTitle";
import TestItem from "../../ui/TestItem";

const TestPreview = ({ items, removeCustomItemHandler }: any) => {
    return (
        <Box width="100%">
            <>
                <PageTitle>Previzualizare test</PageTitle>
                {items.length === 0 ? (
                    <Typography>Nu sunt itemi</Typography>
                ) : (
                    <>
                        {items.map((item: any, index: any) => (
                            <Box mt={3} mb={3} width="100%" key={index}>
                                <BoxContent>
                                    <Box width="100%" display="flex" justifyContent="end" mb={3}>
                                        <GenericButton
                                            type="delete"
                                            onClick={() => {
                                                removeCustomItemHandler(item);
                                            }}
                                        />
                                    </Box>
                                    <TestItem item={item}/>
                                </BoxContent>
                            </Box>
                        ))}
                    </>
                )}
            </>
        </Box>
    );
};

export default TestPreview;
