import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import PageTitle from "components/ui/PageTitle";
import { APIGetAddresses } from "api/pages/addresses";
import { APIDeleteAddress } from "api/pages/address";
import Loading from "components/ui/Loading";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import Icon from "components/ui/Icon";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import BoxContent from "components/ui/BoxContent";
import GenericButton from "components/ui/GenericButton";
import DashboardBox from "components/dashboard/DashboardBox";
import CustomButton from "../../components/ui/CustomButton";

const Addresses = () => {
  const { update } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [addresses, setAddresses] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    APIGetAddresses()
      .then((response) => {
        if (response.data.success) {
          setAddresses(response.data.data.data);
          setLoading(false);
        } else {
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        update("error", { show: true, code: "A001" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editAddressHandler = (id: number) => {
    navigate(`/adrese/${id}/editare`);
  };

  const addAddressHandler = () => {
    navigate(`/adrese/adauga`);
  };

  const deleteAddress = (id: string, callback: Function) => {
    update("loading", { show: true, modal: true });
    APIDeleteAddress({ id })
      .then((response) => {
        if (response.data.success) {
          update("loading", { show: false });
          setAddresses(addresses.filter((item) => item.id.toString() !== id.toString()));
          // navigate("/elevi");
        } else {
          update("loading", { show: false });
          update("error", { show: true, code: "A002" });
        }
        callback();
      })
      .catch((err) => {
        update("loading", { show: false });
        update("error", { show: true, code: "A001" });
      });
  };

  const confirmDelete = (address: { id: number; name: string; phone: string; city: string; [key: string]: any }) => {
    update("confirm", {
      show: true,
      closable: true,
      title: "Ești sigur că vrei să ștergi?",
      message: (
        <>
          Acțiunea va șterge definitiv adresa
          <strong> {address.name}</strong>!
        </>
      ),
      returnFunction: (callback: Function) => {
        deleteAddress(address.id.toString(), callback);
      },
      buttonYes: "Șterge",
      buttonNo: "Închide",
    });
  };

  return (
    <Box component="main">
      <PageTitle
        actions={
          <GenericButton
            type="add"
            onClick={() => {
              addAddressHandler();
            }}
          />
        }
      >
        Adresele mele
      </PageTitle>
      {loading ? (
        <Loading show={true} />
      ) : addresses.length === 0 ? (
        <BoxContent>
          <Typography variant="body1">Nicio adresă introdusă în platformă.</Typography>
            <CustomButton onClick={addAddressHandler} buttonType="textIcon" bgcolor="dark" icon={<Icon icon="plus" fixedWidth={true} sx={{ fontSize: "1.25rem" }} />}>
                Adaugă adresă
            </CustomButton>
        </BoxContent>
      ) : (
        <>
          <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="stretch" mt={0}>
            {addresses.map((address, index) => (
              <Grid item md={12} xl={6} key={index}>
                  <DashboardBox
                      type="jsx"
                      number={0}
                      title={address.name}
                      icon="fal map-location-dot"
                      actions={
                          <Box display={{xs: "block", md:"flex"}} sx={{textAlign: "right"}}>
                              <Box sx={{margin: {xs: "0 0 10px 0", md: "0 10px 0 0"}}}>
                                  <GenericButton
                                      type="edit-small"
                                      onClick={() => {
                                          editAddressHandler(address.id);
                                      }}
                                  />
                              </Box>
                              <GenericButton type="delete" onClick={() => confirmDelete(address)} />
                          </Box>
                      }
                  >
                  <Grid container spacing={1.5} alignItems="center">
                    <Grid item xs={12} sm={2} md={2}  sx={{ textAlign: { xs: "left", sm: "right" } }}>
                        <Typography variant="body2">Adresă:</Typography>
                    </Grid>
                      <Grid item xs={12} sm={10} md={10}>
                      {address.line1}, {address.city}, {address.county}, {address.country}
                    </Grid>
                      <Grid item xs={12} sm={2} md={2}  sx={{ textAlign: { xs: "left", sm: "right" } }}>
                          <Typography variant="body2">Cod poștal:</Typography>
                      </Grid>
                      <Grid item xs={12} sm={10} md={10} >
                          {address.postal_code}
                      </Grid>
                      <Grid item xs={12} sm={2} md={2}  sx={{ textAlign: { xs: "left", sm: "right" } }}>
                          <Typography variant="body2">Telefon:</Typography>
                      </Grid>
                      <Grid item xs={12} sm={10} md={10} >
                          {address.phone}
                      </Grid>
                      {address.is_company && (
                          <>
                              <Grid item xs={12} sm={2} md={2}  sx={{ textAlign: { xs: "left", sm: "right" } }}>
                                  <Typography variant="body2">Nume companie:</Typography>
                              </Grid>
                              <Grid item xs={12} sm={10} md={10} >
                                  {address.company_name}
                              </Grid>
                              <Grid item xs={12} sm={2} md={2} sx={{ textAlign: { xs: "left", sm: "right" } }}>
                                  <Typography variant="body2">CUI:</Typography>
                              </Grid>
                              <Grid item xs={12} sm={10} md={10} >
                                  {address.company_fiscal_code}
                              </Grid>
                              <Grid item xs={12} sm={2} md={2}  sx={{ textAlign: { xs: "left", sm: "right" } }}>
                                  <Typography variant="body2">Număr înregistrare:</Typography>
                              </Grid>
                              <Grid item xs={12} sm={10} md={10} >
                                  {address.company_registration}
                              </Grid>
                          </>
                      )}
                  </Grid>
                  </DashboardBox>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default Addresses;
