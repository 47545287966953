import {useAuth} from "../../hooks/useAuth";
import React, {useEffect, useState} from "react";
import CustomDataTable, {Order} from "../ui/CustomDataTable";
import {APIDeleteTag, APIGetTags} from "../../api/pages/testTags";
import {SubmitHandler} from "react-hook-form";
import SearchForm, {IFormInputs} from "../form/SearchForm";
import Box from "@mui/material/Box";
import GenericButton from "../ui/GenericButton";
import Loading from "../ui/Loading";
import moment from "moment/moment";

const UserTags = () => {
    const { update } = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [createdTags, setCreatedTags] = useState<any[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [perPage, setPerPage] = useState<number>(15);
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<string>("name");
    const [query, setQuery] = useState<string>("");
    const [search, setSearch] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        APIGetTags({ page: `?page=${page + 1}`, sort: orderBy, order: order, search: query })
            .then((response) => {
                if (response.data.success) {
                    setCreatedTags(response.data.data.data);
                    setTotalRows(response.data.data.total);
                    setPerPage(response.data.data.per_page);
                    setLoading(false);
                } else {
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                // console.log("getTeachers ERR", err);
                update("error", { show: true, code: "A001" });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, order, orderBy, query, search]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setCreatedTags([]);
        setPage(newPage);
    };

    const handleSearch: SubmitHandler<IFormInputs> = (data) => {
        setQuery(data.keywords);
        setPage(0);
        setSearch(true);
    };

    const handleClearSearch = () => {
        setQuery("");
        setSearch(false);
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        setPage(0);
    };

    const deleteTag = (id: string, callback: Function) => {
        update("loading", { show: true, modal: true });
        APIDeleteTag({ id })
            .then((response) => {
                if (response.data.success) {
                    // console.log("deleteTeacher OK", response);
                    update("loading", { show: false });
                    setCreatedTags(createdTags.filter((item) => item.id.toString() !== id.toString()));
                    // navigate("/profesori");
                } else {
                    update("loading", { show: false });
                    update("error", { show: true, code: "A002" });
                }
                callback();
            })
            .catch((err) => {
                // console.log("deleteTeacher ERR", err);
                update("loading", { show: false });
                update("error", { show: true, code: "A001" });
            });
    };

    const confirmDelete = (tag: {
        id: string;
        name: string;
    }) => {
        update("confirm", {
            show: true,
            closable: true,
            title: "Ești sigur că vrei să ștergi acest tag?",
            message: (
                <>
                    Acțiunea va șterge definitiv tagul
                    <strong> {tag.name}</strong>!
                </>
            ),
            returnFunction: (callback: Function) => {
                deleteTag(tag.id.toString(), callback);
            },
            buttonYes: "Șterge",
            buttonNo: "Închide",
        });
    };

    return (
        <>
            <Box display="flex" justifyContent="end" width="100%" sx={{ marginBottom: { xs: 1, sm: 3 },}}>
                <SearchForm
                    onSubmit={handleSearch}
                    onChange={(e) => setQuery(e.target.value)}
                    onClick={handleClearSearch}
                    query={query}
                />
            </Box>
            {loading ? (
                <Loading show={true} />
            ) : createdTags.length === 0 ? (
                <Box>Niciun tag adăugat.</Box>
            ) : (
                <CustomDataTable
                    columns={[
                        {
                            id: "name",
                            label: "Nume",
                            numeric: false,
                            disablePadding: false,
                            sortable: true,
                            noWrap: true,
                        },
                        {
                            id: "date",
                            label: "Creat la",
                            numeric: false,
                            disablePadding: false,
                            sortable: false,
                        },
                        {
                            id: "actions",
                            label: "Acțiuni",
                            numeric: true,
                            noWrap: false,
                            disablePadding: true,
                        },
                    ]}
                    rows={createdTags.map((createdTag) => {
                        return {
                            name: createdTag.name,
                            date: moment(createdTag.created_at).locale("ro").format("DD MMMM YYYY"),
                            actions: (
                                <GenericButton type="delete" onClick={() => confirmDelete(createdTag)} />
                            ),
                        };
                    })}
                    rowsPerPage={perPage}
                    page={page}
                    totalRows={totalRows}
                    onPageChange={handleChangePage}
                    handleRequestSort={handleRequestSort}
                    orderBy={orderBy}
                    order={order}
                />
            )}
        </>
    );
}

export default UserTags;