import Box from "@mui/material/Box";
import PageTitle from "../../components/ui/PageTitle";
import GenericButton from "../../components/ui/GenericButton";
import BoxContent from "../../components/ui/BoxContent";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useRouteMatch} from "../../utils/functions";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BrioTags from "../../components/tags/BrioTags";
import UserTags from "../../components/tags/UserTags";

const TestTags = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const routeMatch = useRouteMatch(["/taguri", "/taguri/taguri-adaugate"]);
    const currentTab = routeMatch?.pattern?.path;

    return (
        <Box component="main">
            <PageTitle
                actions={
                    <GenericButton
                        type="add"
                        onClick={() => {
                            navigate("/taguri/adauga");
                        }}
                    />
                }>
                Taguri
            </PageTitle>
            <BoxContent>
                <>
                    <Box sx={{ width: "100%" }}>
                        <Box mb={2} sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={currentTab}
                                sx={{
                                    "& .MuiTabs-flexContainer": {
                                        flexWrap: "wrap",
                                    },
                                }}
                            >
                                <Tab
                                    label="Taguri Brio"
                                    value="/taguri"
                                    to="/taguri"
                                    component={Link}
                                    sx={{color: "secondary.light", "& :hover, & .active": "primary.main"}}
                                />
                                <Tab
                                    label="Taguri adaugate"
                                    value="/taguri/taguri-adaugate"
                                    to="/taguri/taguri-adaugate"
                                    component={Link}
                                    sx={{color: "secondary.light", "& :hover, & .active": "primary.main"}}
                                />
                            </Tabs>
                        </Box>
                        {location.pathname === "/taguri" && <BrioTags/>}
                        {location.pathname === "/taguri/taguri-adaugate" && <UserTags/>}
                    </Box>
                </>
            </BoxContent>
        </Box>
    );

}

export default TestTags;