import React, {SyntheticEvent, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Icon from "components/ui/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import { BehaviorSubject, of, merge } from "rxjs";
import { debounceTime, map, distinctUntilChanged, filter, switchMap, catchError } from "rxjs/operators";
import { IOptions } from "api/pages/teachers";
import { AxiosResponse } from "axios";
import Typography from "@mui/material/Typography";

export const AutocompleteTextFieldStyled = styled(TextField)(({ theme }) => ({
  "& .MuiFilledInput-root, & .MuiFilledInput-root:hover": {
    backgroundColor: `${theme.palette.secondary.contrastText} !important`,
    border: "none",
    borderRadius: theme.spacing(1.5),
    // padding: `${theme.spacing(1.5)} !important`,
    padding: 0,
    color: theme.palette.text.secondary,
    // transition: "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
  },
  "& .MuiChip-root": {
    color: theme.palette.text.secondary,
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
  "& .MuiChip-deleteIcon": {
    color: "rgba(0, 40, 155, 0.26)!important",
  },
  "& .MuiFilledInput-root.Mui-error": {
    border: "1px solid #9D0000",
  },
  "& .MuiInputLabel-root": {
    paddingLeft: theme.spacing(1),
  },
  "& .MuiFilledInput-root.Mui-focused": {
    borderRadius: theme.spacing(1.5),
  },
  "& .MuiFilledInput-root:before, & .MuiFilledInput-root:after": {
    border: "none !important",
  },
  "& label, & label.Mui-focused": {
    color: `${theme.palette.text.secondary} !important`,
    // color: "green",
  },
  "& label.Mui-error": {
    color: `${theme.palette.error.main} !important`,
    // color: "green",
  },
  "& .MuiAutocomplete-popper": {},
  "& .MuiFilledInput-input": {
    // backgroundColor: `${theme.palette.background.light} !important`,
    color: `${theme.palette.text.secondary} !important`,
    border: "none",
    borderRadius: theme.spacing(1.5),
    padding: `${theme.spacing(1.4, 2)} !important`,
  },
  "& .Mui-disabled": {
    color: `${theme.palette.text.disabled} !important`,
  },
  "& label.MuiInputLabel-shrink": {
    display: "none",
  },
  "& .MuiIconButton-root": {
    color: theme.palette.primary.light,
  },

}));

export const PaperStyled = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  backgroundColor: `${theme.palette.primary.light}`,
  color: `${theme.palette.text.primary}`,
  // boxShadow: "none",
  // border: "1px solid #98C0FF",
}));

interface Option {
  value: string | number;
  label: string;
  [key: string]: any;
}
interface Props {
  name: string;
  defaultValue?: string | number | any[] | null;
  label?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  error?: boolean;
  className?: string;
  options?: Option[];
  multiple?: boolean;
  onInputChange?: (e: any) => void;
  onChange?: (event: any, value: any) => void;
  optionsAPI: (options: IOptions) => Promise<AxiosResponse<any, any>>;
  filterOptions?: any;
  loading?: boolean;
  controlled?: boolean;
  value?: string | object | null;
}

const AutocompleteAPI = ({
  name,
  defaultValue,
  label,
  optionsAPI,
  multiple = false,
  filterOptions,
  onChange,
  controlled = false,
  value,
  error = false,
}: Props) => {
  const methods = useFormContext();

  // RXJS autocomplete query
  const [state, setState] = useState({
    data: [],
    loading: false,
    errorMessage: "",
    noResults: false,
  });

  const [subject, setSubject] = useState<BehaviorSubject<string> | null>(null);

  useEffect(() => {
    if (subject === null) {
      const sub = new BehaviorSubject("");
      setSubject(sub);
    } else {
      const observable = subject
        .pipe(
          map((s) => s.trim()),
          distinctUntilChanged(),
          filter((s) => s.length >= 3),
          debounceTime(750),
          switchMap((term) =>
            merge(
              of({ loading: true, errorMessage: "", noResults: false }),
              optionsAPI({ page: "?page=$1", search: term }).then(async (response) => {
                if (response.data.success) {
                  // console.log("getTeachers OK", response);
                  let data = await response.data.data.data;
                  return { data, loading: false, noResults: data.length === 0 };
                } else {
                  return {
                    data: [],
                    loading: false,
                    errorMessage:
                      "Din păcate a intervenit o eroare la comunicarea cu serverul. Vă rugăm încercați mai târziu. Dacă problema persistă, ne puteți contacta la contact@brio.ro.",
                  };
                }
              }),
            ),
          ),
          catchError(async (e) => ({
            loading: false,
            errorMessage:
              "Din păcate, serverul a trimis un mesaj de eroare. Vă rugăm încercați mai târziu. Dacă problema persistă, ne puteți contacta la contact@brio.ro.",
          })),
        )
        .subscribe((newState) => {
          setState((s) => Object.assign({}, s, newState));
        });

      return () => {
        // console.log("unsubscribed!!!");
        observable.unsubscribe();
        subject.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject]);

  const onInputChange = (data: string) => {
    // console.log("onInputChange", e.target);
    if (subject) {
      return subject.next(data);
    }
  };

  return (
    <>
      {controlled ? (
        <Controller
          name={name}
          control={methods.control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Autocomplete
              {...field}
              // freeSolo
              options={
                state.data &&
                state.data.map((item: any) => {
                  if (item.first_name && item.last_name) {
                    return {
                      label: `${item.first_name} ${item.last_name}`,
                      value: item.id,
                      ...item,
                    };
                  } else {
                    return {
                      label: item.name,
                      value: item.id,
                      ...item,
                    };
                  }
                })
              }
              blurOnSelect={true}
              getOptionLabel={(option) => option.label}
              // popupIcon={
              //   <Box sx={{ fontSize: "1.25rem" }} color="secondary.light">
              //     <Icon icon="caret-down" fixedWidth={true} />
              //   </Box>
              // }
              clearIcon={
                <Box sx={{ fontSize: "1.25rem" }} color="secondary.light">
                  <Icon icon="xmark" fixedWidth={true} />
                </Box>
              }
              PaperComponent={PaperStyled}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              noOptionsText={state.errorMessage ? state.errorMessage : "Nu sunt rezultate"}
              renderOption={(props, option) => {
                return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                );
              }}
              renderInput={(params) => (
                <AutocompleteTextFieldStyled
                  {...params}
                  label={label}
                  variant="filled"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              onChange={onChange}
              onInputChange={(_, data) => onInputChange(data)}
              filterOptions={filterOptions}
              multiple={multiple}
              loading={state.loading}
              value={value}
            />
          )}
        />
      ) : name === "school" ? (
        <Controller
          name={name}
          control={methods.control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Autocomplete
              {...field}
              // freeSolo
                renderOption={(props, option, {selected}) => {
                  const { key, ...optionProps } = props as React.HTMLAttributes<HTMLLIElement> & { key: string };
                  return (
                      <li key={key} {...optionProps} >
                        <Box>
                          <Typography mb={0}>{option.name}</Typography>
                          <Typography variant="body2">Localitate: {option.city} ({option.county})</Typography>
                        </Box>
                      </li>
                  )
                }}
              options={
                state.data &&
                state.data.map((item: any) => {
                    return {
                      label: `${item.name}, ${item.city} (${item.county})`,
                      value: item.id,
                      ...item,
                    };
                })
              }
              blurOnSelect={true}
              getOptionLabel={(option: any) => option.label}
              clearIcon={
                <Box sx={{ fontSize: "1.25rem" }} color="secondary.light">
                  <Icon icon="xmark" fixedWidth={true} />
                </Box>
              }
              PaperComponent={PaperStyled}
              isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
              noOptionsText={state.errorMessage ? state.errorMessage : "Nu sunt rezultate"}
              renderInput={(params: any) => (
                <AutocompleteTextFieldStyled
                  {...params}
                  error={error}
                  label={label}
                  variant="filled"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              onChange={(e: SyntheticEvent, data: any) => field.onChange(data)}
              onInputChange={(e: SyntheticEvent, data: any) => onInputChange(data)}
              filterOptions={filterOptions}
              multiple={multiple}
              loading={state.loading}
            />
          )}
        />
      ) : (
        <Controller
        name={name}
      control={methods.control}
      defaultValue={defaultValue}
      render={({ field }) => (
          <Autocomplete
              {...field}
              // freeSolo
              options={
                  state.data &&
                  state.data.map((item: any) => {
                    if (item.hasOwnProperty("first_name") && item.hasOwnProperty("last_name")) {
                      return {
                        label: `${item.first_name} ${item.last_name}`,
                        value: item.id,
                        ...item,
                      };
                    } else {
                      return {
                        label: item.name,
                        value: item.id,
                        ...item,
                      };
                    }
                  })
              }
              blurOnSelect={true}
              getOptionLabel={(option: any) => option.label}
              // popupIcon={
              //   <Box sx={{ fontSize: "1rem" }}>
              //     <Icon icon="caret-down" fixedWidth={true} />
              //   </Box>
              // }
              clearIcon={
                <Box sx={{ fontSize: "1.25rem" }} color="secondary.light">
                  <Icon icon="xmark" fixedWidth={true} />
                </Box>
              }
              PaperComponent={PaperStyled}
              isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
              noOptionsText={state.errorMessage ? state.errorMessage : "Nu sunt rezultate"}
              renderInput={(params: any) => (
                  <AutocompleteTextFieldStyled
                      {...params}
                      error={error}
                      label={label}
                      variant="filled"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                              {state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                      }}
                  />
              )}
              onChange={(e: SyntheticEvent, data: any) => field.onChange(data)}
              onInputChange={(e: SyntheticEvent, data: any) => onInputChange(data)}
              filterOptions={filterOptions}
              multiple={multiple}
              loading={state.loading}
          />
      )}
    />
        )}
    </>
  );
};

export default AutocompleteAPI;
