import axiosClient from "api/apiClient";

export interface IOptions {
    page: string;
    sort?: string;
    order?: string;
    search?: string;
    subject?: number;
    grade?: number;
    subcategory?: number;
    active_items?: boolean;
}

export const APIGetCustomItems = (options: IOptions) => {
    let sort = "";
    let search = "";
    let subject = "";
    let grade = "";
    let subcategory = "";
    let active_items = "";

    if (options.hasOwnProperty("sort")) {
        if(options.order === "asc") {
            sort = "&sort=" + options.sort;
        } else if (options.order === "desc") {
            sort = "&sort=-" + options.sort;
        }
    }

    if (options.hasOwnProperty("search")) {
        search = "&filter[search]=" + options.search;
    }

    if(options.hasOwnProperty("subject")) {
        subject = "&subject="+ options.subject;
    }

    if(options.hasOwnProperty("grade")) {
        grade = "&grade="+ options.grade;
    }

    if(options.hasOwnProperty("subcategory")) {
        subcategory = "&subcategory="+ options.subcategory;
    }

    if(options.hasOwnProperty("active_items")) {
        active_items = "&active_items="+ options.active_items;
    }

    return axiosClient.get(`/personalItems${options.page}${search}${sort}${subject}${grade}${subcategory}${active_items}`);
};

