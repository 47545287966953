import axiosClient from "../apiClient";

interface IOptions {
    page: string;
    sort?: string;
    order?: string;
    search?: string;
}

export const APIPostMedia = (data: object) => {
    return axiosClient.post(`/media`, data);
};

export const APIGetMedia = (options: IOptions) => {
    let sort = "";
    let search = "";
    if (options.hasOwnProperty("sort")) {
        if(options.order === "asc") {
            sort = "&sort=" + options.sort;
        } else if (options.order === "desc") {
            sort = "&sort=-" + options.sort;
        }
    }
    if (options.hasOwnProperty("search")) {
        search = "&filter[search]=" + options.search;
    }
    return axiosClient.get(`/media${options.page}${search}${sort}`);
};

export const APIDeleteMedia = (id: string) => {
    return axiosClient.delete(`/media/${id}`);
};