import React from "react";
import { useAuth } from "hooks/useAuth";
import RegisterForm from "components/login/teachers/RegisterForm";
import { Navigate } from "react-router-dom";
import LinkStyled from "../../components/ui/LinkStyled";

const RegisterPage = () => {
  const { tenant } = useAuth();

  if (tenant.type === "school") {
    return <Navigate to="/" />;
  }

  return (
      <>
        <RegisterForm />
        <LinkStyled to="/login/profesori">Autentificare</LinkStyled>
      </>
      );
};

export default RegisterPage;
